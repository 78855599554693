import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CardIcon from './CardIcon';
import RecipeList from '../RecipeList';

class NutritionStatsCard extends Component {
  render() {
    const { nutritionStats = {} } = this.props;
    const {
      recipesLogged,
      customRecipes,
      allTimeGreenThumbs,
      topRecipes = [],
      timeSaved,
    } = nutritionStats;
    return (
      <Card className="card-default card-container card-large mb-3 nutrition-stats-card flex-grow-1">
        <CardHeader>Nutrition Stats</CardHeader>
        <CardBody>
          {/* <Row>
            <Col className="green-thumb-column d-flex flex-column">
              <div className="flex flex-row justify-content-center">
                <div className="green-thumb-container align-self-center mb-2 mr-2">
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" />
                </div>
                <div className="green-thumb-container align-self-center mb-2 mr-2">
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" />
                </div>
                <div className="green-thumb-container align-self-center mb-2">
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" />
                </div>
              </div>
              <div className="statistic mb-1">{allTimeGreenThumbs || 0}</div>
              <div className="subtitle">
                {`All-Time Green Thumb${allTimeGreenThumbs !== 1 ? 's' : ''}`}
              </div>
            </Col>
          </Row> */}
          <Row className="h-100">
            <Col>
              <Row>
                <Col>
                  <div className="subtitle">Macrostax Recipes Logged</div>
                  <div className="statistic mb-3">{recipesLogged || 0}</div>
                </Col>
                <Col>
                  <div>
                    <span className="subtitle">Custom Recipes Created</span>
                  </div>
                  <div>
                    <span className="statistic mr-2">{customRecipes || 0}</span>
                  </div>
                </Col>
              </Row>
              <Row className="border-top pt-3">
                <Col>
                  <div className="subtitle mb-2">Top {topRecipes.length} Macrostax Recipes</div>
                  {topRecipes.length > 0 ? (
                    <RecipeList recipeList={topRecipes} />
                  ) : (
                    <div className="no-data-label">
                      <p>No one has logged any recipes yet.</p>
                      <p>
                        Macrostax has hundreds of macro-friendly recipes. -{' '}
                        <Link to="/">see what's on the menu!</Link>
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div>
            <span className="subtitle">Time Saved in Nutrition Coaching</span>
            <CardIcon helpText="TIME_SAVED_IN_NUTRITION_COACHING" />
          </div>
          <div>
            <span className="statistic mr-2">{timeSaved || 0}</span>
            <span>hours/week</span>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

NutritionStatsCard.propTypes = {
  nutritionStats: PropTypes.instanceOf(Object),
};

export default NutritionStatsCard;
