import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';
import renderInputForField from '../helpers/renderInputForField';
import renderCheckboxForField from '../helpers/renderCheckboxForField';
import StateField from '../fields/StateField';
import { capitalizeFirst } from '../../utils/stringUtils';
import customFeatures from '../../data/customFeatures.json';

class MacrostaxLinksFormPartial extends Component {
  renderCheckbox = (feature, team) => {
    const { doAddHiddenFeature, doDeleteHiddenFeature } = this.props;
    return (
      <>
        <Field
          name={feature.value}
          component={renderCheckboxForField}
          {...{
            itemProps: {},
            checkboxIcon: '',
            checkboxIconUnchecked: '',
            inputProps: {},
            labelClassName: 'switch switch-lg',
            label: feature.name.replace('{{teamName}}', team.settings.name),
          }}
        />
        <OnChange name={feature.value}>
          {(value) => {
            console.log(feature.value, value);
            if (value) {
              doDeleteHiddenFeature(feature.value);
            } else {
              doAddHiddenFeature(feature.value);
            }
          }}
        </OnChange>
      </>
    );
  };

  render() {
    const { hiddenFeatures, team } = this.props;
    const firstColLength = Math.ceil(customFeatures.length / 2);
    const secondColLength = customFeatures.length - firstColLength;

    const firstCol = customFeatures.slice(0, firstColLength);
    const secondCol = customFeatures.slice(firstColLength);
    const cols = [firstCol, secondCol];
    return (
      <>
        <Row>
          <Col>
            {firstCol.map((c) => (
              <div key={c.name}>{this.renderCheckbox(c, team)}</div>
            ))}
          </Col>
          <Col>
            {secondCol.map((c) => (
              <div key={c.name}>{this.renderCheckbox(c, team)}</div>
            ))}
          </Col>
        </Row>
      </>
    );
  }
}

MacrostaxLinksFormPartial.propTypes = {
  team: PropTypes.instanceOf(Object),
  doAddHiddenFeature: PropTypes.func,
  doDeleteHiddenFeature: PropTypes.func,
};

export default MacrostaxLinksFormPartial;
