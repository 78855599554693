import React from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import DropdownSubMenu from '../../components/Common/DropdownSubMenu';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import SampleClientProfileModal from '../../modals/SampleClientProfileModal';
import AddClientForm from '../../forms/AddClientForm';
import AddClientsFromCsvForm from '../../forms/AddClientsFromCsvForm';
import PendingClientsForm from '../../forms/PendingClientsForm';
import { submissionError } from '../../actions/formActions';
import {
  addClients,
  clearClientsSearchForm,
  clearPendingClients,
  getPendingClients,
  sendInvitations,
  submitPendingClientsSearchRequest,
} from '../../actions/teamActions';
import CSVUploader from '../../components/Common/CSVUploader.tsx';
import browserHistory from '../../history';
import ManageInvitesSearchForm from '../../forms/ManageInvitesSearchForm';

class AddClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'addclients',
      activeSub: 'individually',
      sampleModalOpen: false,
      results: [],
      csvInitialValues: {},
      initialValues: {
        send_invitations: true,
      },
    };
  }

  componentDidMount() {
    if (window.location.hash) {
      setTimeout(() => {
        this.toggleTab(window.location.hash.replace('#', ''), false);
      });
    }
  }

  toggleTab = (activeTab, doPush = true) => {
    this.setState(
      {
        activeTab,
      },
      () => {
        if (activeTab === 'invites') {
          this.refreshPendingClients();
        }
        if (doPush) {
          window.history.pushState('', '', `/addclients#${activeTab}`);
        }
      }
    );
  };

  setActiveSub = (activeSub) => {
    this.setState({
      activeSub,
    });
  };

  toggleSampleModal = () => {
    const { sampleModalOpen } = this.state;
    this.setState({
      sampleModalOpen: !sampleModalOpen,
    });
  };

  mapResults = (csv) => {
    const results = csv.csv;
    if (results.errors && results.errors.length) {
      alert('Error');
      console.log(results.errors);
    } else {
      if (results.data[0].length !== 3) {
        alert('Incorrect CSV format - should be "first_name","last_name","email"');
      } else {
        const usersToUpload = [];
        const csvInitialValues = {
          send_invitations: true,
        };
        for (let i = 0; i < results.data.length; i += 1) {
          const user = results.data[i];
          usersToUpload.push({
            first_name: user[0],
            last_name: user[1],
            email: user[2],
          });
          csvInitialValues[`user_${i}`] = true;
        }
        this.setState({ results: usersToUpload, csvInitialValues });
      }
    }
  };

  refreshPendingClients = () => {
    const { doClearPendingClients, doGetPendingClients } = this.props;
    doClearPendingClients();
    doGetPendingClients();
  };

  goToUserDetail = (user) => {
    browserHistory.push({
      pathname: `/clients/${user.id}`,
      state: { from: 'Manage invites' }
    });
  };

  render() {
    const { showSubmissionError, doAddClients, team, doSendInvitations, user, searchPendingClients, resetSearchPendingClients } = this.props;
    const {
      sampleModalOpen,
      activeTab,
      results,
      csvInitialValues,
      activeSub,
      initialValues,
    } = this.state;
    let name;
    let pendingClients;
    if (team && team.settings) {
      ({ name } = team.settings);
    }
    if (team && team.pendingClients) {
      ({ pendingClients } = team);
    }

    const tabButtons = [
      {
        label: 'Add Clients',
        id: 'addclients',
        icon: 'fa fa-user-plus',
        onClick: () => this.toggleTab('addclients'),
      },
      // {
      //   label: 'Upload CSV File',
      //   id: 'csv',
      //   icon: 'fa fa-file-csv',
      //   onClick: () => this.toggleTab('csv'),
      // },
      {
        label: 'Manage Invites',
        id: 'invites',
        icon: 'fa fa-user-clock',
        onClick: () => {
          this.toggleTab('invites');
          // this.refreshPendingClients();
        },
      },
    ];

    const buttons = [
      {
        title: 'Upload Individually',
        id: 'individually',
      },
      {
        title: 'Upload CSV File',
        id: 'csv',
      },
    ];

    return (
      <ContentWrapper className="add-clients">
        <Row>
          <Col xs={12}>
            <PageTitle title="Add Clients" />
          </Col>
        </Row>
        {team && team.settings ? (
          <div className="main-content-container">
            <div className="d-md-none">
              <DropdownSubMenu
                buttons={tabButtons}
                title="Add Clients"
                // title={`${activeTab.charAt(0).toUpperCase()}${activeTab.substring(1)}`}
              />
            </div>
            <Row className="mt-4">
              <Col xs={12}>
                <Nav tabs className="d-none d-md-flex">
                  {tabButtons.map((t) => (
                    <NavItem
                      key={t.id}
                      className={activeTab === t.id.toLowerCase() ? 'active' : ''}
                    >
                      <NavLink onClick={t.onClick}>
                        {t.icon && <em className={t.icon} />}
                        {t.label}
                      </NavLink>
                      <div className="tab-indicator animated" />
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>
            <TabContent activeTab={activeTab} className="animated no-top-margin">
              <TabPane tabId="addclients">
                <Row>
                  <Col>
                    <div className="btn-group btn-group-toggle mt-4">
                      {buttons.map((b) => (
                        <label
                          key={b.id}
                          className={`btn btn-secondary ${activeSub === b.id && 'active'}`}
                        >
                          <input
                            type="radio"
                            name="options"
                            id={b.id}
                            autoComplete="off"
                            defaultChecked={activeSub === b.id}
                            onClick={() => this.setState({ activeSub: b.id })}
                          />{' '}
                          {b.title}
                        </label>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    {activeSub === 'individually' ? (
                      <>
                        <Row>
                          <Col sm="12">
                            <p>Step 1 is to upload your clients to your Macrostax Team account.</p>
                            <p>
                              Enter the name and email of each client that you would like to add to
                              your Macrostax Team account. Select whether you’d like to send these
                              clients an invite to join your Team now, or otherwise manage client
                              invites later via the Manage Invites tab.{' '}
                              <span className="span-link" onClick={this.toggleSampleModal}>
                                Learn more
                              </span>
                              .
                            </p>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={4}>First Name</Col>
                          <Col xs={4}>Last Name</Col>
                          <Col xs={4}>Email</Col>
                        </Row>
                        <Row>
                          <Col sm="12">
                            <AddClientForm
                              showSubmissionError={showSubmissionError}
                              onSubmit={(values, form, rows) => {
                                const newInitialValues = {
                                  send_invitations: true,
                                };
                                for (let i = 0; i < rows; i += 1) {
                                  newInitialValues[`first_name_${i}`] = '';
                                  newInitialValues[`last_name_${i}`] = '';
                                  newInitialValues[`email_${i}`] = '';
                                }
                                doAddClients(values, () => {
                                  setTimeout(() => {
                                    form.reset(newInitialValues);
                                  });
                                  this.toggleTab('invites');
                                });
                              }}
                              rows={5}
                              initialValues={initialValues}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col sm={12}>
                            <p>Step 1 is to upload your clients to your Macrostax Team account.</p>
                            <p>
                              Upload a CSV file to bulk add multiple clients at one time. You will
                              have the ability to review the client list prior to adding each client
                              to {name}.
                            </p>
                            <p>
                              The CSV file should have 3 columns containing first name, last name,
                              and email address (in that order), with no row headers. Check out this{' '}
                              <a href="/users.csv">sample CSV file</a> for reference. Need help?
                              Contact us through{' '}
                              <span id="Intercom" className="span-link intercom">
                                StaxChat
                              </span>
                              .
                            </p>
                            <p>
                              You can also select whether you’d like to send your clients an invite
                              to join your Team now, or manage invites via the Manage Invites tab
                              later.{' '}
                              <span className="span-link" onClick={this.toggleSampleModal}>
                                Learn more
                              </span>
                              .
                            </p>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4} xs={8} className="cursor-pointer">
                            <CSVUploader onLoaded={(csv) => this.mapResults({ csv })} />
                          </Col>
                        </Row>
                        {results && results.length ? (
                          <AddClientsFromCsvForm
                            showSubmissionError={showSubmissionError}
                            onSubmit={(values) => {
                              doAddClients(values, () => this.toggleTab('invites'));
                            }}
                            users={results}
                            initialValues={csvInitialValues}
                          />
                        ) : null}
                      </>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="invites">
                <Row className="mt-4">
                  <Col sm="12">
                    {this.props.pendingClients ? (
                      <>
                        <p>Step 2 is to invite your clients to join your Macrostax Team.</p>
                        <p>
                          When you invite a client they will receive an email from Macrostax
                          prompting them to sign up to join your Team.{' '}
                          <span className="span-link" onClick={this.toggleSampleModal}>
                            Learn more
                          </span>{' '}
                          about this email.
                        </p>
                        <p>
                          This screen displays all clients you have uploaded to your Macrostax
                          account along with the status of their invitation. You can send, resend,
                          and remove invites below.
                        </p>
                        <>
                          {/* The following{' '}
                            {`${pendingClients.length} client${
                              pendingClients.length !== 1 ? 's' : ''
                            } ha${pendingClients.length === 1 ? 's' : 've'} `}
                            been added to your account but{' '}
                            {`ha${pendingClients.length === 1 ? 's' : 've'} `} not yet been
                            activated. */}
                          <div className="mt-3">
                            <Row className="mt-6">
                              <Col xs={12}>
                                <ManageInvitesSearchForm
                                  showSubmissionError={showSubmissionError}
                                  onSubmit={searchPendingClients}
                                  onReset={resetSearchPendingClients}
                                  teamProfile={team.profile}
                                  currentUser={user}
                                  initialValues={this.props.initialValues}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="mt-3">
                            <PendingClientsForm
                              showSubmissionError={showSubmissionError}
                              onSubmit={(values) => {
                                doSendInvitations(values);
                              }}
                              users={this.props?.pendingClients ?? []}
                              initialValues={{}}
                              team={team}
                              onRowClick={(rowData) => this.goToUserDetail(rowData)}
                            />
                          </div>
                        </>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <SampleClientProfileModal isOpen={sampleModalOpen} toggle={this.toggleSampleModal} />
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

AddClients.propTypes = {
  showSubmissionError: PropTypes.func,
  doAddClients: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doGetPendingClients: PropTypes.func,
  doClearPendingClients: PropTypes.func,
  doSendInvitations: PropTypes.func,
  searchPendingClients: PropTypes.func,
  resetSearchPendingClients: PropTypes.func,
  pendingClients: PropTypes.instanceOf(Array),
};
const mapStateToProps = (state) => ({
  team: state.team,
  pendingClients: state.team.pendingClients,
  user: state.auth.user,
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    user_role_status: ['active', 'invited', 'pending', 'pre-active', 'onboarding'],
    ...state.context.clientsSearchForm,
  },
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doAddClients: (values, func) => dispatch(addClients(values, func)),
  doGetPendingClients: () => dispatch(getPendingClients()),
  doClearPendingClients: () => dispatch(clearPendingClients()),
  doSendInvitations: (values) => dispatch(sendInvitations(values)),
  searchPendingClients: (values) => dispatch(submitPendingClientsSearchRequest(values)),
  resetSearchPendingClients: () => {
    dispatch(clearClientsSearchForm());
    dispatch(submitPendingClientsSearchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClients);
