import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ButtonToolbar,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ButtonGroupNavigation from '../Common/ButtonGroupNavigation';
import GoalLeaderboard from '../Leaderboards/GoalLeaderboard';
import SingleStatisticPieChart from '../Charts/SingleStatisticPieChart';

class TeamResultsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 'cut',
      ddOpen: false,
    };
  }

  toggleDD = () => {
    const { ddOpen } = this.state;
    this.setState({
      ddOpen: !ddOpen,
    });
  };

  onChange = (option) => {
    this.setState({
      activeButton: option,
    });
  };

  render() {
    const { teamResults, timestamp } = this.props;
    const { ddOpen, activeButton } = this.state;
    const goal = teamResults.goals[activeButton] ? teamResults.goals[activeButton] : {};
    const label = activeButton === 'cut' || activeButton === 'reversedieting' ? 'Lost' : 'Gained';
    const buttons = [
      {
        title: 'Fat Loss',
        id: 'cut',
      },
      {
        title: 'Muscle Gain',
        id: 'gain',
      },
      {
        title: 'Maintenance',
        id: 'maintenance',
      },
      {
        title: 'Reverse Dieting',
        id: 'reversedieting',
      },
    ];
    return (
      <Card className="card-default card-container card-large mb-3 nutrition-stats-card flex-grow-1">
        <CardHeader className="d-flex justify-content-between">
          <div>Team Results</div>
          <ButtonToolbar className="card-dropdown">
            <ButtonDropdown isOpen={ddOpen} toggle={() => this.toggleDD()}>
              <DropdownToggle caret>{activeButton}</DropdownToggle>
              <DropdownMenu>
                {buttons.map((d) => (
                  <DropdownItem key={d.id} onClick={() => this.onChange(d.id)}>
                    {d.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonToolbar>
          {/* <ButtonGroupNavigation
            buttons={buttons}
            onClick={(btn) => this.setState({ activeButton: btn.id })}
            activeButton={activeButton}
          /> */}
        </CardHeader>
        {activeButton !== 'maintenance' && (
          <CardBody>
            <GoalLeaderboard
              leaderboard={goal.leaderboards || []}
              goal={activeButton}
              lbsVerb={label}
            />
          </CardBody>
        )}
        <CardBody className={activeButton !== 'maintenance' ? 'card-body-section' : ''}>
          <Row>
            <Col xs={6}>
              <div className="subtitle mb-2">Clients in {activeButton} Plan</div>
              <div className="d-flex flex-row align-items-center justify-content-between mt-3">
                <div className="w-75">
                  <SingleStatisticPieChart
                    percent={goal.clients.percentage}
                    label={activeButton}
                    timestamp={timestamp}
                  />
                </div>
                <div>
                  <div className="statistic-2">{`${goal.clients.percentage}%`}</div>
                  <div className="card-caption">
                    {`${goal.clients.count} `}
                    clients
                  </div>
                </div>
              </div>
            </Col>
            {activeButton !== 'maintenance' && (
              <Col xs={6}>
                <div className="ml-4">
                  <div className="subtitle mb-2"># of Weigh Ins, Last 30 Days</div>
                  <div className="statistic">{goal.statistics.weighIns30Days}</div>
                  <div className="no-data-label">
                    <Link to="/">Send a reminder to log weight</Link> to clients in this plan
                  </div>
                </div>
              </Col>
            )}
          </Row>
          {activeButton === 'maintenance' && (
            <Row>
              <Col>
                <div className="subtitle mb-2 mt-4">Why don't I see weigh in data here?</div>
                <p>
                  Clients in the Maintenance plan are typically less focused on weight-related
                  results as Fat Loss and Muscle Gain.
                </p>
                <p>
                  As we further refine our results tracking for Maintenance clients, we'll release
                  new data to help you better understand these clients' results! Have thoughts?{' '}
                  <Link to="/">Let us know.</Link>
                </p>
              </Col>
            </Row>
          )}
        </CardBody>
        {activeButton !== 'maintenance' && (
          <CardBody className="card-body-section">
            <Row>
              <Col xs={6}>
                <div className="subtitle mb-2">Total Lbs {label}</div>
                <div>
                  <span className="statistic mr-2">{goal.statistics.lbs || 0}</span>
                  <span>lbs</span>
                </div>
                <div className="caption">From {goal.statistics.lbsBy} clients</div>
              </Col>
              <Col>
                <div className="ml-4">
                  <div className="subtitle mb-2">Total # of Weigh Ins</div>
                  <div className="statistic">{goal.statistics.weighInsTotal}</div>
                  <div className="caption">From {goal.statistics.weighInsBy} clients</div>
                </div>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    );
  }
}

TeamResultsCard.propTypes = {
  teamResults: PropTypes.instanceOf(Object),
  timestamp: PropTypes.number, // used to force a re-render of the pie chart when on a hidden tab
};

export default TeamResultsCard;
