import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, FormGroup } from 'reactstrap';
import moment from 'moment';
import createDecorator from 'final-form-calculate';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderSelectForField from './helpers/renderSelectForField';
import FirstNameField from './fields/FirstNameField';
import LastNameField from './fields/LastNameField';
import EmailField from './fields/EmailField';
import PhoneField from './fields/PhoneField';
import { subscriptionPlanOptions } from '../enums/subscriptionPlans';
import { genderOptions } from '../enums/genders';
import { productOptions } from '../enums/products';
import { getGoalOptionsFromTeamProfile, getPhaseOptions } from '../utils/teamProfileUtils';
import { isTeamUser } from '../utils/authUtils';
import { USER_ROLE_OPTIONS } from '../constants';

const validate = () => {
  const errors = {};
  return errors;
};

class UserSearchForm extends Component {
  constructor(props) {
    super(props);
    const goalOptions = getGoalOptionsFromTeamProfile(props.teamProfile);
    this.state = {
      goalOptions,
      invalidator: createDecorator({
        field: 'goal_id', // when a field matching this pattern changes...
        updates: {
          phase_id: (goal_id, allValues, ..._) => {
            if (!goal_id || !allValues.phase_id) {
              return goal_id ? allValues.phase_id : undefined;
            }
            const validPhases = allValues.phase_id.filter((v) =>
              getPhaseOptions(goal_id, goalOptions)
                .map((o) => o.value)
                .indexOf(v) !== -1
                ? v
                : false
            );
            return validPhases.length !== 0 ? validPhases : undefined;
          },
        },
      }),
    };
  }
  render() {
    const { initialValues, onSubmit, showSubmissionError, onReset, currentUser } = this.props;
    const current_user_roles = currentUser.roles;
    const teamAdmin = isTeamUser(current_user_roles);
    const activityLevelOptions = this.props.teamProfile.activity_levels
      .sort((a, b) => (a.display_order < b.display_order ? -1 : 1))
      .map((al) => {
        return { value: al.activity_level_id, label: al.activity_level_name };
      });

    return (
      <div>
        <Form
          onSubmit={(values) => {
            let vals = false;
            for (let i = 0; i < Object.keys(values).length; i += 1) {
              if (values[Object.keys(values)[i]]) {
                vals = true;
              }
            }
            if (!vals && !teamAdmin) {
              showSubmissionError({ error: 'Please enter some search criteria' });
            } else {
              const errors = validate(values);
              if (Object.keys(errors).length === 0) {
                onSubmit(values);
              } else {
                showSubmissionError(errors);
              }
            }
          }}
          initialValues={initialValues}
          decorators={[this.state.invalidator]}
          render={({ handleSubmit, submitting, form, ...props }) => {
            const phaseOptions = getPhaseOptions(props.values.goal_id, this.state.goalOptions);
            return (
              <form onSubmit={handleSubmit} className="inline-form">
                <Row>
                  <Col md={6}>
                    <FirstNameField />
                    <LastNameField />
                    <EmailField />
                    {!teamAdmin && <PhoneField />}
                    {!teamAdmin && (
                      <Field
                        name="user_id"
                        component={renderInputForField}
                        label="User ID"
                        stackedlabel
                        {...{
                          inputProps: {
                            type: 'text',
                          },
                          itemProps: {},
                        }}
                      />
                    )}
                    {!teamAdmin && (
                      <FormGroup className="stacked centered">
                        <label>Created</label>
                        <div className="d-flex centered range-container">
                          <Field
                            name="start_created_at"
                            component={renderDatepickerForField}
                            stackedlabel
                            {...{
                              itemProps: { row: false },
                              inputProps: {
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select',
                                maxDate: new Date(),
                              },
                            }}
                          />
                          <div className="caption">to</div>
                          <Field
                            name="end_created_at"
                            component={renderDatepickerForField}
                            stackedlabel
                            {...{
                              itemProps: { row: false },
                              inputProps: {
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select',
                                maxDate: new Date(),
                              },
                            }}
                          />
                        </div>
                      </FormGroup>
                    )}
                    {teamAdmin && (
                      <Field
                        name="user_role_status"
                        label="Account Status"
                        initialValue={initialValues.user_role_status}
                        component={renderSelectForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {
                            options: USER_ROLE_OPTIONS,
                            isMulti: true,
                          },
                        }}
                      />
                    )}
                  </Col>
                  <Col>
                    {/*
                    <Field
                      name="customerId"
                      component={renderInputForField}
                      label="Customer ID"
                      stackedlabel
                      {...{
                        inputProps: {
                          type: 'text',
                        },
                        itemProps: {},
                      }}
                    />
                    <Field
                      name="subscriptionId"
                      component={renderInputForField}
                      label="Subscription ID"
                      stackedlabel
                      {...{
                        inputProps: {
                          type: 'text',
                        },
                        itemProps: {},
                      }}
                    />
                    <Field
                      name="subscriptionPlan"
                      label="Subscription Plan"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: subscriptionPlanOptions,
                          isMulti: true,
                        },
                      }}
                    />
                    */}
                    <Field
                      name="goal_id"
                      label="Goal"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: this.state.goalOptions,
                          isMulti: true,
                        },
                      }}
                    />
                    <Field
                      name="phase_id"
                      label="Phase"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: phaseOptions,
                          isMulti: true,
                          value: props.values.phase_id,
                        },
                      }}
                    />
                    <Field
                      name="activity_level_id"
                      label="Activity Level"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: activityLevelOptions,
                          isMulti: true,
                        },
                      }}
                    />
                    {!teamAdmin && (
                      <Field
                        name="gender"
                        label="Gender"
                        component={renderSelectForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {
                            options: genderOptions,
                          },
                        }}
                      />
                    )}
                    {!teamAdmin && (
                      <FormGroup className="stacked centered">
                        <label>Birthdate</label>
                        <div className="d-flex centered range-container">
                          <Field
                            name="start_birthdate"
                            component={renderDatepickerForField}
                            stackedlabel
                            {...{
                              itemProps: { row: false },
                              inputProps: {
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select',
                                maxDate: moment().add(-13, 'years').toDate(),
                              },
                            }}
                          />
                          <div className="caption">to</div>
                          <Field
                            name="end_birthdate"
                            component={renderDatepickerForField}
                            stackedlabel
                            {...{
                              itemProps: { row: false },
                              inputProps: {
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select',
                                maxDate: moment().add(-13, 'years').toDate(),
                              },
                            }}
                          />
                        </div>
                      </FormGroup>
                    )}
                    {!teamAdmin && (
                      <Field
                        name="product"
                        label="Product"
                        component={renderSelectForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {
                            options: productOptions,
                          },
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <button
                      className="btn btn-lg btn-secondary fixed-width-md"
                      type="reset"
                      onClick={() => {
                        form.reset();
                        onReset();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-lg btn-primary fixed-width-md ml-3 btn-search-with-reset"
                      type="submit"
                      disabled={submitting}
                    >
                      Search
                    </button>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

UserSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onReset: PropTypes.func,
  teamProfile: PropTypes.instanceOf(Object),
  currentUser: PropTypes.instanceOf(Object),
};

export default UserSearchForm;
