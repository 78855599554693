import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import md5 from 'md5';
import { shuffleArray } from '../../utils/arrayUtils';

class UserAvatar extends Component {
  render() {
    const { user, showName = false, team, badgeCount = '0' } = this.props;
    let first_name;
    let last_name;
    let profile_photo;
    let email;

    if (user) {
      ({ first_name = '', last_name = '', status, profile_photo, email } = user);
      // if (!profile_photo && email) {
      //   profile_photo = `https://www.gravatar.com/avatar/${md5(email.trim().toLowerCase())}?d=blank`;
      // }
    } else if (team) {
      const { businessName, logoUrl } = team;
      first_name = businessName;
      profile_photo = logoUrl;
    }

    const username = `${first_name} ${last_name}`;
    const initials = `${first_name.charAt(0)}${last_name ? last_name.charAt(0) : ''}`;
    // profile_photo = 'https://randomuser.me/api/portraits/women/33.jpg';

    return (
      <div className="user-avatar">
        {/* <div className="user-avatar-photo">
          <img className="" src={profile_photo} />
        </div>
        <div className="user-avatar-initials">{initials}</div> */}
        {profile_photo ? (
          <div className="user-avatar-photo">
            <img className="" src={profile_photo} alt={username} />
          </div>
        ) : (
          <div className="user-avatar-initials">{initials}</div>
        )}
        {showName && first_name !== undefined && first_name.length && <div className="user-avatar-name">{`${first_name} ${last_name}`}</div>}
        {badgeCount !== undefined && badgeCount !== '0' && (
          <Badge tag="div" className="animated" color="danger">
            {badgeCount}
          </Badge>
        )}
      </div>
    );
  }
}

UserAvatar.propTypes = {
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  showName: PropTypes.bool,
  badgeCount: PropTypes.string,
};

export default UserAvatar;
