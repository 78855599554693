import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment-timezone';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  VirtualizedMessageList
} from 'stream-chat-react';
import ENV_VARS from '../../utils/envVars';
import { formatFromNowDate } from '../../utils/dateUtils';

import 'stream-chat-react/dist/css/v2/index.css';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);

export function ChatModule({ currentUser, targetUser, chatTokens }) {
  // chatClient.connectUser(
  //   {
  //     id: currentUser.user_id,
  //     name: `${currentUser.first_name} ${currentUser.last_name}`,
  //   },
  //   chatTokens[currentUser.user_id]
  // );

  const channel = chatClient.channel('messaging', {
    members: [currentUser.user_id, targetUser.user_id],
  });
  
  return (
    <div className="planner">
      <Row>
        <Col>
          <Chat client={chatClient} theme="str-chat__theme-light">
            <Channel channel={channel}>
              <Window>
                {/* <ChannelHeader /> */}
                {/* <VirtualizedMessageList disableDateSeparator={true} formatDate={(date) => moment(date).fromNow() }/> */}
                <MessageList disableDateSeparator={false} formatDate={formatFromNowDate}/>
                <MessageInput grow={true} />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </Col>
      </Row>
    </div>
  );
}
// class ChatModule extends Component {
//   render() {
//     const { currentUser, targetUser, chatTokens } = this.props;

//     chatClient.connectUser(
//       {
//         id: currentUser.user_id,
//         name: `${currentUser.first_name} ${currentUser.last_name}`,
//       },
//       chatTokens[currentUser.user_id]
//     );

//     const channel = chatClient.channel('messaging', {
//       members: [currentUser.user_id, targetUser.user_id],
//     });

//     return (
//       <div className="planner">
//         <Row>
//           <Col>
//             <Chat client={chatClient} theme="str-chat__theme-light">
//               <Channel channel={channel}>
//                 <Window>
//                   {/* <ChannelHeader /> */}
//                   {/* <VirtualizedMessageList disableDateSeparator={true} formatDate={(date) => moment(date).fromNow() }/> */}
//                   <MessageList disableDateSeparator={true} formatDate={(date) => moment(date).fromNow() }/>
//                   <MessageInput />
//                 </Window>
//                 <Thread />
//               </Channel>
//             </Chat>
//           </Col>
//         </Row>
//       </div>
//     );
//   }
// }

ChatModule.propTypes = {
  currentUser: PropTypes.instanceOf(Object),
  targetUser: PropTypes.instanceOf(Object),
  chatTokens: PropTypes.instanceOf(Object),
};

export default ChatModule;
