import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import UserAvatar from '../Common/UserAvatar';
import RemoveAdminUserButton from '../Common/RemoveAdminUserButton';
import RemoveMembershipButton from '../Common/RemoveMembershipButton';
import Pill from '../Common/Pill';
import tableIcons from '../../utils/muiIcons';
import { capitalizeFirst } from '../../utils/stringUtils';
import {
  getClassNameForSubscriptionStatus,
  getFriendlyNameForUserStatus,
} from '../../utils/subscriptionUtils';

class AdminTable extends Component {
  render() {
    const { users, title = 'Admins', toolbar = true, search = true, statusColumn = 'user_role_status', isTeamView = true } = this.props;
    return (
      <MaterialTable
        columns={[
          // {
          //   title: '',
          //   field: 'profilePhoto',
          //   render: (rowData) => <UserAvatar user={rowData} />,
          //   sorting: false,
          //   width: null,
          //   cellStyle: {
          //     width: 100,
          //     maxWidth: 100,
          //   },
          // },
          { title: 'Last Name', field: 'last_name' },
          { title: 'First Name', field: 'first_name' },
          { title: 'Email', field: 'email' },
          {
            title: 'Admin Status',
            field: statusColumn,
            render: (rowData) => (
              <Pill
                title={
                  isTeamView
                    ? getFriendlyNameForUserStatus(rowData[statusColumn])
                    : capitalizeFirst(rowData[statusColumn])
                }
                className={getClassNameForSubscriptionStatus(rowData[statusColumn])}
              />
            ),
          },
          // {
          //   title: 'Role',
          //   field: 'role',
          //   render: (rowData) => capitalizeFirst(rowData.role.replace('team_', '')),
          // },
          // {
          //   title: '',
          //   render: (rowData) => <RemoveAdminUserButton user={rowData} />,
          // },
          // {
          //   title: '',
          //   render: (rowData) => <RemoveMembershipButton user={rowData} />,
          // },
        ]}
        data={users}
        title={title}
        options={{
          padding: 'dense',
          pageSize: users.length < 10 ? users.length : 10,
          search: false,
          paging: users.length > 10,
          toolbar,
          search,
          thirdSortClick: false,
        }}
        style={{
          backgroundColor: '#f7f7f7',
        }}
        icons={tableIcons}
      />
    );
  }
}

AdminTable.propTypes = {
  title: PropTypes.string,
  users: PropTypes.instanceOf(Object),
  toolbar: PropTypes.bool,
  search: PropTypes.bool,
};

export default AdminTable;
