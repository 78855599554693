import { call, put, take, race, all, delay } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import {
  SUBMIT_USER_SEARCH_REQUEST,
  SET_CUSTOM_MACROS,
  RESET_TARGETS,
  GET_USER_PROFILE,
  UPDATE_USER,
  getUser,
  GET_MACRO_TARGETS,
  ADD_WEIGH_IN,
  UPDATE_WEIGH_IN,
  DELETE_WEIGH_IN,
  ADD_BODY_FAT,
  UPDATE_BODY_FAT,
  DELETE_BODY_FAT,
  ADD_MEASUREMENTS,
  UPDATE_MEASUREMENTS,
  DELETE_MEASUREMENTS,
  GET_PLAN,
  GET_MEMBERSHIP,
  SET_PASSWORD_FOR_USER,
  SYNC_INTERCOM_CONTACT,
  GET_DAILY_MACROS,
  GET_ALL_USER_MACROS,
  setPasswordForUser,
  getDailyMacros as getDailyMacrosAction,
  getAllUserMacros as getAllUserMacrosAction,
} from '../actions/userActions';
import {
  getUserProfile,
  getSparseUserProfile,
  updateUserProfile,
  getMacroTargets,
  users,
  userSearch,
  addWeighInApi,
  updateWeighInApi,
  deleteWeighInApi,
  addBodyFatApi,
  updateBodyFatApi,
  deleteBodyFatApi,
  addMeasurementsApi,
  updateMeasurementsApi,
  deleteMeasurementsApi,
  getPlanApi,
  getMembershipApi,
  setPasswordForUserApi,
  syncIntercomContact,
  getDailyMacros,
  getAllUserMacros,
  setCustomMacros,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  GET_TARGETS_SUCCESS,
  GET_TARGETS_FAILURE,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  WEIGH_INS_SUCCESS,
  WEIGH_INS_FAILURE,
  BODY_FATS_SUCCESS,
  BODY_FATS_FAILURE,
  MEASUREMENTS_SUCCESS,
  MEASUREMENTS_FAILURE,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_FAILURE,
  SET_PASSWORD_FOR_USER_SUCCESS,
  SET_PASSWORD_FOR_USER_FAILURE,
  SYNC_INTERCOM_CONTACT_SUCCESS,
  SYNC_INTERCOM_CONTACT_FAILURE,
  GET_DAILY_MACROS_SUCCESS,
  GET_DAILY_MACROS_FAILURE,
  GET_ALL_USER_MACROS_SUCCESS,
  GET_ALL_USER_MACROS_FAILURE,
  SET_CUSTOM_MACROS_FAILURE,
  SET_CUSTOM_MACROS_SUCCESS,
} from '../apiActions/userApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';
import { DayTypes } from '../enums/dayTypes';

export default function* userSagas() {
  yield all([
    getUserSaga(),
    updateUserSaga(),
    submitUserSearchSaga(),
    getTargetsSaga(),
    setCustomMacrosSaga(),
    resetTargetsSaga(),
    addWeighInSaga(),
    editWeighInSaga(),
    deleteWeighInSaga(),
    addBodyFatSaga(),
    editBodyFatSaga(),
    deleteBodyFatSaga(),
    addMeasurementsSaga(),
    editMeasurementsSaga(),
    deleteMeasurementsSaga(),
    getPlanSaga(),
    getMembershipSaga(),
    setPasswordForUserSaga(),
    syncIntercomUserSaga(),
    getDailyMacrosSaga(),
    getAllUserMacrosSaga(),
  ]);
}

function* getUserSaga() {
  while (true) {
    const { user_id } = yield take(GET_USER_PROFILE);
    yield put(getUserProfile(user_id));
    const { success, failure } = yield race({
      success: take(USER_PROFILE_SUCCESS),
      failure: take(USER_PROFILE_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(getAllUserMacrosAction(user_id, success.data.selected_team_id))
    }
  }
}

function* getPlanSaga() {
  while (true) {
    const { user_id, date, switchTab } = yield take(GET_PLAN);
    yield put(getPlanApi(user_id, date));
    const { success, failure } = yield race({
      success: take(GET_PLAN_SUCCESS),
      failure: take(GET_PLAN_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else if (switchTab !== undefined && switchTab !== null) {
      yield call(switchTab);
    }
  }
}

function* getDailyMacrosSaga() {
  while (true) {
    const { user_id, start_date, end_date } = yield take(GET_DAILY_MACROS);
    yield put(getDailyMacros(user_id, start_date, end_date));
    const { success, failure } = yield race({
      success: take(GET_DAILY_MACROS_SUCCESS),
      failure: take(GET_DAILY_MACROS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* syncIntercomUserSaga() {
  while (true) {
    const { user_id, team_id } = yield take(SYNC_INTERCOM_CONTACT);
    yield put(syncIntercomContact(user_id, team_id));
    const { success, failure } = yield race({
      success: take(SYNC_INTERCOM_CONTACT_SUCCESS),
      failure: take(SYNC_INTERCOM_CONTACT_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Done!', 'User synced to Intercom successfully', 'success'));
    }
  }
}

function* setPasswordForUserSaga() {
  while (true) {
    const {
      user_id,
      values: { new_password },
    } = yield take(SET_PASSWORD_FOR_USER);
    yield put(setPasswordForUserApi(user_id, new_password));
    const { success, failure } = yield race({
      success: take(SET_PASSWORD_FOR_USER_SUCCESS),
      failure: take(SET_PASSWORD_FOR_USER_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Success!', 'Password reset successfully', 'success'));
    }
  }
}

function* getMembershipSaga() {
  while (true) {
    const { user_id } = yield take(GET_MEMBERSHIP);
    yield put(getMembershipApi(user_id));
    const { success, failure } = yield race({
      success: take(GET_MEMBERSHIP_SUCCESS),
      failure: take(GET_MEMBERSHIP_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      if (
        failure &&
        failure.error &&
        failure.error.response &&
        failure.error.response.data &&
        failure.error.response.data.error &&
        failure.error.response.data.error === 'We could not find a membership for that user'
      ) {
        // handled by the reducer
      } else {
        yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
      }
    }
  }
}

function* updateUserSaga() {
  while (true) {
    const { id, payload, auth_user } = yield take(UPDATE_USER);
    yield put(updateUserProfile({ user_id: id, ...payload }));
    const { success, failure } = yield race({
      success: take(UPDATE_USER_SUCCESS),
      failure: take(UPDATE_USER_FAILURE),
    });
    if (success) {
      if (auth_user) {
        yield put(getSparseUserProfile(id));
      } else {
        yield put(getUser(id));
      }
      yield put(
        showAlert(
          'Done!',
          `${payload.first_name} ${payload.last_name}'${
            payload.last_name.charAt(payload.last_name.length - 1) !== 's' ? 's' : ''
          } data was sucessfully updated.`,
          'success'
        )
      );
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* submitUserSearchSaga() {
  while (true) {
    const { values } = yield take(SUBMIT_USER_SEARCH_REQUEST);
    const v = {
      ...values,
    }
    if (v.start_created_at) {
      v.start_created_at = moment(v.start_created_at).format('YYYY-MM-DD');
    }
    if (v.end_created_at) {
      v.end_created_at = moment(v.end_created_at).format('YYYY-MM-DD');
    }
    if (v.start_birthdate) {
      v.start_birthdate = moment(v.start_birthdate).format('YYYY-MM-DD');
    }
    if (v.end_birthdate) {
      v.end_birthdate = moment(v.end_birthdate).format('YYYY-MM-DD');
    }
    if (!v || Object.keys(v).length === 0) {
      // dispatch api call action for all users
      yield put(users());
    } else {
      // dispatch api call action for user search
      yield put(userSearch(v));
    }
    const { success, failure } = yield race({
      success: take(USER_SEARCH_SUCCESS),
      failure: take(USER_SEARCH_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getTargetsSaga() {
  while (true) {
    const { user_id, team_id } = yield take(GET_MACRO_TARGETS);
    yield put(getMacroTargets(user_id, team_id));
    const { success, failure } = yield race({
      success: take(GET_TARGETS_SUCCESS),
      failure: take(GET_TARGETS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      // yield put(showAlert('Oops!', 'There was an error loading this user\'s macro targets', 'error'));
    }
  }
}

function* setCustomMacrosSaga() {
  while (true) {
    const { user_id, team_id, firstname, lastname, timezone, payload } = yield take(SET_CUSTOM_MACROS);
    const macros = [];
    for (let i = 0; i < Object.keys(DayTypes).length; i += 1) {
      const day_type = Object.keys(DayTypes)[i];
      macros.push({
        day_type_id: day_type,
        carbs: parseInt(payload[`${day_type}_carbs`]),
        protein: parseInt(payload[`${day_type}_protein`]),
        fat: parseInt(payload[`${day_type}_fat`]),
      });
    }
    
    yield put(setCustomMacros(user_id, team_id, timezone, macros));
    const { success, failure } = yield race({
      success: take(SET_CUSTOM_MACROS_SUCCESS),
      failure: take(SET_CUSTOM_MACROS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', 'There was an error setting this user\'s custom macro targets', 'error'));
    } else {
      let startDate = moment();
      let selectedDate = moment();
      let endDate;
      startDate = selectedDate.clone().startOf('month').add(-3, 'M');
      endDate = selectedDate.clone().endOf('month').add(2, 'M');
      yield put(getDailyMacrosAction(user_id, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')));
      yield put(getAllUserMacrosAction(user_id, team_id));
      yield put(
        showAlert(
          'Done!',
          `${firstname} ${lastname}'${
            lastname.charAt(lastname.length - 1) !== 's' ? 's' : ''
          } custom macros have been saved! They will see their macro targets update in their app automatically.`,
          'success',
          undefined,
        )
      );
    }
  }
}

function* resetTargetsSaga() {
  while (true) {
    const { user_id, team_id, firstname, lastname, timezone, payload } = yield take(RESET_TARGETS);
    const macros = [];
    // for (let i = 0; i < Object.keys(DayTypes).length; i += 1) {
    //   const day_type = Object.keys(DayTypes)[i];
    //   macros.push({
    //     day_type_id: day_type,
    //     carbs: parseInt(payload[`${day_type}_carbs`]),
    //     protein: parseInt(payload[`${day_type}_protein`]),
    //     fat: parseInt(payload[`${day_type}_fat`]),
    //   });
    // }
    
    yield put(setCustomMacros(user_id, team_id, timezone, macros));
    const { success, failure } = yield race({
      success: take(SET_CUSTOM_MACROS_SUCCESS),
      failure: take(SET_CUSTOM_MACROS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', 'There was an error setting this user\'s custom macro targets', 'error'));
    } else {
      yield put(getAllUserMacrosAction(user_id, team_id));
      yield put(
        showAlert(
          'Done!',
          `${firstname} ${lastname}'${
            lastname.charAt(lastname.length - 1) !== 's' ? 's' : ''
          } macro targets have been set to use the Macrostax formula. They will see their macro targets update in their app automatically.`,
          'success',
          undefined,
          
        )
      );
    }
  }
}

function* addWeighInSaga() {
  while (true) {
    const { id, values } = yield take(ADD_WEIGH_IN);
    yield put(addWeighInApi(id, values));
    const { success, failure } = yield race({
      success: take(WEIGH_INS_SUCCESS),
      failure: take(WEIGH_INS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Weigh-ins were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* editWeighInSaga() {
  while (true) {
    const { id, values } = yield take(UPDATE_WEIGH_IN);
    yield put(updateWeighInApi(id, values));
    const { success, failure } = yield race({
      success: take(WEIGH_INS_SUCCESS),
      failure: take(WEIGH_INS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Weigh-ins were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* deleteWeighInSaga() {
  while (true) {
    const { id, date } = yield take(DELETE_WEIGH_IN);
    yield put(deleteWeighInApi(id, date));
    const { success, failure } = yield race({
      success: take(WEIGH_INS_SUCCESS),
      failure: take(WEIGH_INS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Weigh-ins were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addBodyFatSaga() {
  while (true) {
    const { id, values } = yield take(ADD_BODY_FAT);
    yield put(addBodyFatApi(id, values));
    const { success, failure } = yield race({
      success: take(BODY_FATS_SUCCESS),
      failure: take(BODY_FATS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Body fat measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* editBodyFatSaga() {
  while (true) {
    const { id, values } = yield take(UPDATE_BODY_FAT);
    yield put(updateBodyFatApi(id, values));
    const { success, failure } = yield race({
      success: take(BODY_FATS_SUCCESS),
      failure: take(BODY_FATS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Body fat measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* deleteBodyFatSaga() {
  while (true) {
    const { id, date } = yield take(DELETE_BODY_FAT);
    yield put(deleteBodyFatApi(id, date));
    const { success, failure } = yield race({
      success: take(BODY_FATS_SUCCESS),
      failure: take(BODY_FATS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Body fat measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addMeasurementsSaga() {
  while (true) {
    const { id, values } = yield take(ADD_MEASUREMENTS);
    yield put(addMeasurementsApi(id, values));
    const { success, failure } = yield race({
      success: take(MEASUREMENTS_SUCCESS),
      failure: take(MEASUREMENTS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* editMeasurementsSaga() {
  while (true) {
    const { id, values } = yield take(UPDATE_MEASUREMENTS);
    yield put(updateMeasurementsApi(id, values));
    const { success, failure } = yield race({
      success: take(MEASUREMENTS_SUCCESS),
      failure: take(MEASUREMENTS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* deleteMeasurementsSaga() {
  while (true) {
    const { id, date } = yield take(DELETE_MEASUREMENTS);
    yield put(deleteMeasurementsApi(id, date));
    const { success, failure } = yield race({
      success: take(MEASUREMENTS_SUCCESS),
      failure: take(MEASUREMENTS_FAILURE),
    });
    if (success) {
      yield put(showAlert('Done!', `Measurements were successfully updated.`, 'success'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getAllUserMacrosSaga() {
  while (true) {
    const { user_id, team_id, date } = yield take(GET_ALL_USER_MACROS);
    yield put(getAllUserMacros(user_id, team_id, date));
    const { success, failure } = yield race({
      success: take(GET_ALL_USER_MACROS_SUCCESS),
      failure: take(GET_ALL_USER_MACROS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      // yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}
