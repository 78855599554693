import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';

class Groups extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Groups" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>Coming soon</Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Groups.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
