import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import ReactGA from 'react-ga4';
import PageTitle from '../components/Common/PageTitle';

class SampleDashboardModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/sampleDashboard', title: 'Sample Dashboard' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <PageTitle title="Your Dashboard" showTeam={false} />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={7}>
              <img
                src="https://via.placeholder.com/500x400?text=Dashboard+placeholder+image+here"
                alt="Placeholder"
              />
            </Col>
            <Col>
              <p>
                Your dashboard summarizes how your client base is using their Macrostax memberships.
              </p>
              <ul className="breathing-room">
                <li>
                  See your return on investment by viewing how often your clients use Macrostax
                </li>
                <li>
                  Support nutrition challenges with leaderboards for different nutrition plans
                </li>
                <li>
                  Utilize pre-designed templates to encourage your cilents to track progress and
                  other reminders
                </li>
              </ul>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

SampleDashboardModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SampleDashboardModal;
