// Note: this form is self-contained, meaning all of the dispatch calls are defined
// within this instead of being passed in as props
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import AnimatedNumber from 'react-animated-numbers';
import ReactTextTransition, { presets } from 'react-text-transition';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import { submissionError } from '../actions/formActions';
import { setCustomMacros, resetTargets } from '../actions/userActions';
import renderInputForField from './helpers/renderInputForField';
import { isTeamUser } from '../utils/authUtils';
import { capitalizeFirst } from '../utils/stringUtils';
import { enforceInteger } from '../utils/formUtils';
import CardIcon from '../components/Cards/CardIcon';
import { DayTypes } from '../enums/dayTypes';
import GoalFormModal from '../modals/GoalFormModal';
import { formatGoal, formatPhase, formatActivityLevel } from '../utils/teamProfileUtils';

const macros = ['carbs', 'protein', 'fat'];

const validate = (values, targets) => {
  const errors = {};
  targets.forEach((t) => {
    const { day_type_id } = t;
    macros.forEach((m) => {
      const thisVal = values[`${day_type_id}_${m}`];
      if (thisVal === undefined) {
        errors[`${day_type_id}_${m}`] = `${capitalizeFirst(DayTypes[day_type_id])} day ${m} ${
          m === 'carbs' ? 'are' : 'is'
        } required`;
      }
    });
  });
  return errors;
};

const animateProps = {
  animationType: 'random',
  includeComma: true,
  config: { mass: 1, tension: 210, friction: 20 },
};

class MacroTargetsForm extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const { isCustomMacros } = user;

    this.state = {
      disabled: true,
      viewingMacros: isCustomMacros ? 'custom' : 'macrostax',
      goalFormModalOpen: false,
    };
  }

  componentDidMount() {
    this.calculateCalories();
  }

  toggleGoalFormModal = () => {
    const { goalFormModalOpen } = this.state;
    this.setState({
      goalFormModalOpen: !goalFormModalOpen,
    });
  };

  calculateCalories = (values) => {
    const { user } = this.props;
    const { targets } = user;
    const { custom, macrostax } = targets;
    const { viewingMacros } = this.state;
    const calories = {};
    const plans = targets[viewingMacros];
    if (values) {
      Object.keys(values).forEach((v) => {
        const arr = v.split('_');
        const macro = arr[1];
        const dayType = arr[0];
        if (calories[dayType] === undefined) {
          calories[dayType] = 0;
        }
        if (macro === 'carbs' || macro === 'protein') {
          calories[dayType] += parseFloat(values[v]) * 4;
        } else {
          calories[dayType] += parseFloat(values[v]) * 9;
        }
      });
    } else {
      plans.forEach((t) => {
        if (calories[t.day_type_id] === undefined) {
          calories[t.day_type_id] = 0;
        }
        calories[t.day_type_id] +=
          parseFloat(t.carbs) * 4 + parseFloat(t.protein) * 4 + parseFloat(t.fat) * 9;
      });
    }

    this.setState({
      calories,
    });
  };

  toggleTargetView = (target) => {
    this.setState(
      {
        viewingMacros: target,
      },
      () => this.calculateCalories()
    );
  };

  formatValue = (value) => value.toFixed(0);

  render() {
    const {
      doSetCustomMacros,
      showSubmissionError,
      user,
      doResetTargets,
      currentUser: { roles },
      timestamp,
      render = true,
      team_id,
      teamProfile,
      showEditGoal = false,
      teamName,
    } = this.props;
    // console.log(team_id);
    const { user_id, targets, isCustomMacros, first_name, last_name, timezone } = user;
    const { disabled, viewingMacros, calories = {}, goalFormModalOpen } = this.state;
    let initialValues = {};
    // const tgts = isCustomMacros ? custom : targets;
    const tgts = targets[viewingMacros];
    // const tgts = plans;
    const { goal_id, activity_level_id, phase_id } = targets;
    tgts.forEach(
      (t) =>
        (initialValues = {
          ...initialValues,
          [`${t.day_type_id}_carbs`]: t.carbs,
          [`${t.day_type_id}_protein`]: t.protein,
          [`${t.day_type_id}_fat`]: t.fat,
        })
    );
    const isTeamAdmin = isTeamUser(roles);
    let headerText =
      (isCustomMacros && viewingMacros === 'custom') || !disabled ? 'Custom ' : 'Macrostax ';
    headerText += 'Macro Targets';
    let resetText = 'Reset to Macrostax formula';
    if (viewingMacros !== 'custom') {
      resetText = 'Switch client to these targets';
    }
    return (
      <>
        {render && (
          <>
            <Card
              className={`card-default card-container mb-0 large-header-card flex-grow-1 ${
                isCustomMacros && disabled ? 'bblr-0 bbrr-0' : ''
              }`}
            >
              <CardHeader className="d-flex justify-space-between align-items-center animated">
                <div className="no-wrap">
                  <ReactTextTransition
                    text={headerText}
                    springConfig={presets.stiff}
                    inline
                    noOverflow
                  />
                  {viewingMacros === 'custom' && (
                    <em
                      onClick={() => this.setState({ disabled: !disabled })}
                      className={` cursor-pointer fa-1x ml-2 ${
                        disabled ? 'icon-pencil' : 'icon-close'
                      }`}
                    />
                  )}
                </div>
                {isCustomMacros && (
                  <div className="header-normal-text d-flex">
                    <Button
                      className="mr-2"
                      onClick={() => {
                        this.setState(
                          {
                            viewingMacros: 'targets',
                          },
                          () => this.calculateCalories()
                        );
                        doResetTargets(user_id, team_id, first_name, last_name, timezone);
                      }}
                    >
                      <ReactTextTransition
                        text={resetText}
                        springConfig={presets.stiff}
                        inline
                        noOverflow
                      />
                    </Button>
                    <CardIcon helpText="CUSTOM_MACROS" />
                  </div>
                )}
                {!isCustomMacros && disabled && (
                  <>
                    <div className="header-normal-text d-flex">
                      <Button
                        className="mr-2"
                        onClick={() => {
                          this.setState({
                            disabled: !disabled,
                          });
                        }}
                      >
                        Customize macros
                      </Button>
                      <CardIcon helpText="CUSTOM_MACROS" />
                    </div>
                  </>
                )}
              </CardHeader>
              <CardBody className={isCustomMacros && disabled ? 'pb-0' : ''}>
                {viewingMacros === 'macrostax' &&
                  disabled &&
                  goal_id &&
                  phase_id &&
                  activity_level_id &&
                  showEditGoal && (
                    <Row>
                      <Col style={{marginTop: "-10px", marginLeft: "-6px"}}>
                        <span
                          className="span-link"
                          onClick={this.toggleGoalFormModal}
                        >
                          {formatGoal(teamProfile, goal_id, true)} /{' '}
                          {formatPhase(teamProfile, phase_id, true)}, Activity{' '}
                          {capitalizeFirst(formatActivityLevel(teamProfile, activity_level_id))}
                        </span>
                      </Col>
                    </Row>
                  )}
                <Row>
                  <Col xs={3} className="strong p-2">
                    Day Type
                  </Col>
                  <Col xs={3} className="text-center strong border-left p-2">
                    Carbs
                  </Col>
                  <Col xs={3} className="text-center strong border-left p-2">
                    Protein
                  </Col>
                  <Col xs={3} className="text-center strong border-left p-2">
                    Fat
                  </Col>
                </Row>
                <Form
                  onSubmit={(values) => {
                    const errors = validate(values, tgts);
                    if (Object.keys(errors).length === 0) {
                      doSetCustomMacros(user_id, team_id, first_name, last_name, timezone, values);
                      // this.setState(
                      //   {
                      //     disabled: true,
                      //     viewingMacros: 'custom',
                      //   },
                      //   () => this.calculateCalories()
                      // );
                    } else {
                      showSubmissionError(errors, false);
                    }
                  }}
                  initialValues={initialValues}
                  keepDirtyOnReinitialize={false}
                  render={({ handleSubmit, submitting, form, values }) => (
                    <form onSubmit={handleSubmit} className="macro-targets-form animated">
                      {tgts.map((t) => (
                        <Row className="border-top animated" key={t.day_type_id}>
                          <Col
                            xs={3}
                            className="p-2 align-self-center animated day-type-container col-3"
                          >
                            <div>{capitalizeFirst(DayTypes[t.day_type_id])}</div>
                            <div className="caption">{calories[t.day_type_id]} kcal</div>
                          </Col>
                          <Col xs={3} className="text-center border-left p-2">
                            {disabled ? (
                              <div className=" d-flex flex-row justify-content-center macro-value">
                                {t.carbs}g
                              </div>
                            ) : (
                              <div className="animated">
                                <Field
                                  name={`${t.day_type_id}_carbs`}
                                  component={renderInputForField}
                                  {...{
                                    itemProps: {},
                                    inputProps: {
                                      type: 'tel',
                                      className: 'no-spin',
                                      onInput: enforceInteger,
                                    },
                                  }}
                                />
                                <OnChange name={`${t.day_type_id}_carbs`}>
                                  {() => {
                                    this.calculateCalories(values);
                                  }}
                                </OnChange>
                              </div>
                            )}
                          </Col>
                          <Col xs={3} className="text-center border-left p-2">
                            {disabled ? (
                              <div className=" d-flex flex-row justify-content-center macro-value">
                                {t.protein}g
                              </div>
                            ) : (
                              <div className="animated">
                                <Field
                                  name={`${t.day_type_id}_protein`}
                                  component={renderInputForField}
                                  {...{
                                    itemProps: {},
                                    inputProps: {
                                      type: 'tel',
                                      className: 'no-spin',
                                      onInput: enforceInteger,
                                    },
                                  }}
                                />
                                <OnChange name={`${t.day_type_id}_protein`}>
                                  {() => {
                                    this.calculateCalories(values);
                                  }}
                                </OnChange>
                              </div>
                            )}
                          </Col>
                          <Col xs={3} className="text-center border-left p-2">
                            {disabled ? (
                              <div className=" d-flex flex-row justify-content-center macro-value">
                                {t.fat}g
                              </div>
                            ) : (
                              <div className="animated">
                                <Field
                                  name={`${t.day_type_id}_fat`}
                                  component={renderInputForField}
                                  {...{
                                    itemProps: {},
                                    inputProps: {
                                      type: 'tel',
                                      className: 'no-spin',
                                      onInput: enforceInteger,
                                    },
                                  }}
                                />
                                <OnChange name={`${t.day_type_id}_fat`}>
                                  {() => {
                                    this.calculateCalories(values);
                                  }}
                                </OnChange>
                              </div>
                            )}
                          </Col>
                        </Row>
                      ))}
                      {!disabled && (
                        <Row className="animated pt-3 border-top border-bottom-0 border-left-0 border-right-0">
                          <Col className="d-flex justify-content-end">
                            <Button
                              color="secondary"
                              size="lg"
                              onClick={() =>
                                this.setState({ disabled: true }, () => {
                                  this.calculateCalories();
                                  form.reset();
                                })
                              }
                              className="mr-3 fixed-width-md"
                            >
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              size="lg"
                              type="submit"
                              className="fixed-width-md"
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </form>
                  )}
                />
              </CardBody>
            </Card>
            {goal_id && phase_id && activity_level_id && showEditGoal && (
              <GoalFormModal
                user={user}
                isOpen={goalFormModalOpen}
                toggle={this.toggleGoalFormModal}
                teamName={teamName}
                teamProfile={teamProfile}
                goal_id={goal_id}
                phase_id={phase_id}
                activity_level_id={activity_level_id}
                team_id={team_id}
              />
            )}
            {disabled && isCustomMacros && (
              // <div className=" worksheet-container d-flex pl-2">
              //   <div
              //     className={`worksheetTab ${viewingMacros === 'custom' ? 'active' : ''}`}
              //     onClick={() => this.toggleTargetView('custom')}
              //   >
              //     Custom Macros
              //   </div>
              //   <div
              //     className={`worksheetTab ${viewingMacros === 'targets' ? 'active' : ''}`}
              //     onClick={() => this.toggleTargetView('targets')}
              //   >
              //     Macrostax Macros
              //   </div>
              // </div>
              <Card className="transparent">
                <CardBody>
                  <Row>
                    <Col
                      xs={3}
                      className={`border-left-card animated worksheetTab ${
                        viewingMacros === 'custom' ? 'active' : ''
                      }`}
                      onClick={() => this.toggleTargetView('custom')}
                    >
                      Custom Macros
                    </Col>
                    <Col
                      xs={3}
                      style={{ marginLeft: '0.5px' }}
                      className={`border-left animated border-right worksheetTab ${
                        viewingMacros === 'macrostax' ? 'active' : ''
                      }`}
                      onClick={() => this.toggleTargetView('macrostax')}
                    >
                      Macrostax Macros
                    </Col>
                    <Col xs={3} />
                    <Col xs={3} />
                  </Row>
                </CardBody>
              </Card>
            )}
          </>
        )}
      </>
    );
  }
}

MacroTargetsForm.propTypes = {
  user: PropTypes.instanceOf(Object),
  currentUser: PropTypes.instanceOf(Object),
  doSetCustomMacros: PropTypes.func,
  doResetTargets: PropTypes.func,
  showSubmissionError: PropTypes.func,
  team_id: PropTypes.string,
  showEditGoal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
  doSetCustomMacros: (user_id, team_id, first_name, last_name, timezone, values) =>
    dispatch(setCustomMacros(user_id, team_id, first_name, last_name, timezone, values)),
  doResetTargets: (user_id, team_id, first_name, last_name, timezone) =>
    dispatch(resetTargets(user_id, team_id, first_name, last_name, timezone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MacroTargetsForm);
