import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class GreenThumbsLeaderboard extends Component {
  render() {
    const { leaderboard = [] } = this.props;
    return (
      <div className="leaderboard">
        {leaderboard.map((user, idx) => (
          <Row key={user.id}>
            <Col xs={7}>
              {`${idx + 1}. `}
              <Link to={{
                pathname: `/clients/${user.id}`,
                state: { from: 'Leaderboard' }
              }}
              >{user.name}
              </Link>
            </Col>
            <Col>{`${user.days} day${user.days !== 1 ? 's' : ''}/week`}</Col>
          </Row>
        ))}
        {leaderboard.length === 0 && (
          <Row>
            <Col className="no-data-label">
              <p>None yet.</p>
              <p>
                Once your Team starts seeing more Macrostax usage, we'll list the most successful
                clients here.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

GreenThumbsLeaderboard.propTypes = {
  leaderboard: PropTypes.instanceOf(Object),
};

export default GreenThumbsLeaderboard;
