import Qs from 'qs';
import ENV_VARS from '../utils/envVars';

export const AUTH_BASIC_SUCCESS = 'AUTH_BASIC_SUCCESS';
export const AUTH_BASIC_FAILURE = 'AUTH_BASIC_FAILURE';
export const AUTHORIZE_SUCCESS = 'AUTHORIZE_SUCCESS';
export const AUTHORIZE_FAILURE = 'AUTHORIZE_FAILURE';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export const SEND_RESET_PASSWORD_SUCCESS = 'SEND_RESET_PASSWORD_SUCCESS';
export const SEND_RESET_PASSWORD_FAILURE = 'SEND_RESET_PASSWORD_FAILURE';
export const GET_STREAM_TOKEN_SUCCESS = 'GET_STREAM_TOKEN_SUCCESS';
export const GET_STREAM_TOKEN_FAILURE = 'GET_STREAM_TOKEN_FAILURE';
export const UPSERT_STREAM_USERS_SUCCESS = 'UPSERT_STREAM_USERS_SUCCESS';
export const UPSERT_STREAM_USERS_FAILURE = 'UPSERT_STREAM_USERS_FAILURE';
export const SWITCH_TEAMS_SUCCESS = 'SWITCH_TEAMS_SUCCESS';
export const SWITCH_TEAMS_FAILURE = 'SWITCH_TEAMS_FAILURE';

export function authUserBasic(email, password) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/login',
    method: 'POST',
    data: {
      username: email,
      password,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    spinner: true,
    successAction: AUTH_BASIC_SUCCESS,
    failureAction: AUTH_BASIC_FAILURE,
  };
}

export function authorize(email, password, team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/authorize',
    method: 'POST',
    data: {
      username: email,
      password,
      teamId: team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    spinner: true,
    successAction: AUTHORIZE_SUCCESS,
    failureAction: AUTHORIZE_FAILURE,
  };
}

export function authUserRefresh(token) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/refresh',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    successAction: AUTH_REFRESH_SUCCESS,
    failureAction: AUTH_REFRESH_FAILURE,
  };
}

export function changeUserPassword(data) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/update-password',
    method: 'PUT',
    data,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: PASSWORD_CHANGE_SUCCESS,
    failureAction: PASSWORD_CHANGE_FAILURE,
  };
}

export function sendResetPassword(data) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/temp-password',
    method: 'POST',
    data,
  };
  console.log(config);
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SEND_RESET_PASSWORD_SUCCESS,
    failureAction: SEND_RESET_PASSWORD_FAILURE,
  };
}

export function getStreamToken(user_ids) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/stream-token',
    method: 'GET',
    params: {
      user_id: user_ids,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: false });
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_STREAM_TOKEN_SUCCESS,
    failureAction: GET_STREAM_TOKEN_FAILURE,
  };
}

export function upsertStreamUsers(users) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/stream-users',
    method: 'POST',
    data: {
      users,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPSERT_STREAM_USERS_SUCCESS,
    failureAction: UPSERT_STREAM_USERS_FAILURE,
  };
}

export function switchTeams(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/switch-teams',
    method: 'POST',
    data: {
      team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SWITCH_TEAMS_SUCCESS,
    failureAction: SWITCH_TEAMS_FAILURE,
  };
}
