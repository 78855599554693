import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { launchCalendly } from '../../utils/calendlyUtils';

class Callout extends Component {
  render() {
    const {
      toggle,
      text = "Have a large client list that you'd like to invite?",
      ctaText = 'Our complimentary White Glove Services can help.',
      className,
    } = this.props;
    return (
      <>
        <div
          className={`callout-container d-flex flex-row justify-content-center align-items-center ${className}`}
        >
          <em className="fa-4x ms-icon- icon-stars-light-sparkle1 mr-3" />
          <div>
            <p>{text}</p>
            <p className="mb-0">
              <span
                className="span-link"
                onClick={() => {
                  if (toggle) {
                    toggle();
                  }
                  launchCalendly();
                }}
              >
                {ctaText}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }
}

Callout.propTypes = {
  toggle: PropTypes.func,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  className: PropTypes.string,
};

export default Callout;
