import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';
import renderRadioButtonsForField from './helpers/renderRadioButtonsForField';
import { capitalizeFirst } from '../utils/stringUtils';

const validate = (values) => {
  const errors = {};
  // if (!values.opinion) {
  //   errors.opinion = 'Please select an emoji';
  // }
  if (!values.feedback_type) {
    errors.feedback_type = 'Please select what type of feedback you are submitting';
  }
  if (!values.feedback) {
    errors.feedback = 'Please enter a description of your feedback';
  }
  return errors;
};

class FeedbackForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError, onCancel } = this.props;
    const opinionOptions = [
      {
        value: '0',
        icon: 'ms-icon- icon-face-sad',
      },
      {
        value: '1',
        icon: 'ms-icon- icon-face-neutral',
      },
      {
        value: '2',
        icon: 'ms-icon- icon-face-happy',
      },
    ];
    const typeOptions = [
      {
        value: 'suggestion',
        label: 'Suggestion',
      },
      {
        value: 'bug',
        label: "Something's not quite right",
      },
      {
        value: 'compliment',
        label: 'Compliment',
      },
    ];
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
              // this.sound = new Audio();
              // this.sound.src = '/why.mp3';
              // this.sound.load();
              // this.sound
              //   .play()
              //   .then(() => {
              //     // Audio is playing.
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  {/* <Field
                    name="opinion"
                    options={opinionOptions}
                    component={renderRadioButtonsForField}
                    label="What is your opinion of this page?"
                    optionstype="icon"
                    required
                    {...{
                      inputProps: {},
                      itemProps: {
                        cols: opinionOptions.length,
                        wrapperclass: 'emoji-wrapper justify-content-around',
                      },
                    }}
                  /> */}
                  <Field
                    name="feedback_type"
                    options={typeOptions}
                    component={renderRadioButtonsForField}
                    label="What type of feedback is this?"
                    optionstype="button"
                    required
                    {...{
                      inputProps: {},
                      itemProps: {
                        cols: typeOptions.length,
                        wrapperclass: 'emoji-wrapper justify-content-around',
                      },
                    }}
                  />
                  <Field
                    name="feedback"
                    component={renderInputForField}
                    label="Please enter your feedback below:"
                    format={capitalizeFirst}
                    required
                    stackedlabel
                    labelClassName="full-size mt-3"
                    {...{
                      inputProps: {
                        type: 'textarea',
                        rows: 3,
                      },
                      itemProps: {},
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={onCancel}
                    className="mr-3 fixed-width-md"
                  >
                    Cancel
                  </Button>
                  <Button color="primary" size="lg" type="submit" className="fixed-width-md">
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

FeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FeedbackForm;
