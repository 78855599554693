import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addClients, sendInvitations } from '../../actions/teamActions';
import Loading from '../Common/Loading';
import PendingClientsForm from '../../forms/PendingClientsForm';
import browserHistory from '../../history';

class ManageInvitesControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSub: 'individually',
      results: [],
      csvInitialValues: {},
    };
  }

  goToUserDetail = (user, team_id) => {
    // browserHistory.push(`/users/${user.id}`);
    browserHistory.push(`/users/${user.id}?team_id=${team_id}`);
  };

  render() {
    const { team, doSendInvitations, showSubmissionError, toggleTab } = this.props;
    const { activeSub, results, csvInitialValues } = this.state;
    const { pendingClients } = team;

    return (
      <>
        {pendingClients ? (
          <div className="mt-3">
            <PendingClientsForm
              showSubmissionError={showSubmissionError}
              onSubmit={(values) => {
                doSendInvitations(values, true, team.team_id, () => toggleTab('manageinvites'));
              }}
              users={pendingClients}
              initialValues={{}}
              team={team}
              isInternalAdmin={true}
              toggleTab={() => toggleTab('manageinvites')}
              onRowClick={(rowData) => this.goToUserDetail(rowData, team.team_id)}
            />
          </div>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

ManageInvitesControl.propTypes = {
  team: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  doSendInvitations: PropTypes.func,
  toggleTab: PropTypes.func,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doAddClients: (values, func) => dispatch(addClients(values, func)),
  doSendInvitations: (values, impersonate_team_owner, team_id, func) => dispatch(sendInvitations(values, impersonate_team_owner, team_id, func)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvitesControl);
