import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Row, Col, Button, FormGroup } from 'reactstrap';
import MaterialTable, { MTableToolbar } from 'material-table';
import tableIcons from '../utils/muiIcons';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone';
import Pill from '../components/Common/Pill';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderSelectForField from './helpers/renderSelectForField';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import { usedMacrostaxOptions } from '../enums/usedMacrostax';
import { makeSimpleValue } from '../utils/formUtils';
import { FORM_TRANSITION_PROPS } from '../constants';
import {
  getClassNameForSubscriptionStatus,
  getFriendlyNameForUserStatus,
} from '../utils/subscriptionUtils';

const validate = (values) => {
  const errors = {};
  return errors;
};

class ChallengeClientsForm extends Component {
  constructor(props) {
    super(props);
    const { clients, initialValues } = this.props;
    this.state = {
      select: Object.keys(initialValues).length === clients.length ? 'None' : 'All',
      clients,
    };
  }

  selectAllOrNone = (form) => {
    const { select, clients } = this.state;
    const checked = select === 'All';
    for (let i = 0; i < clients.length; i += 1) {
      form.change(`user_${clients[i].id}`, checked);
    }
    setTimeout(() => {
      this.setState({
        select: select === 'All' ? 'None' : 'All',
      });
    });
  };

  checkValues = (value, values, id) => {
    if (!value) {
      this.setState({
        select: 'All',
      });
    } else {
      values[`user_${id}`] = true;
      const { clients } = this.state;
      let selected = 0;
      for (let i = 0; i < clients.length; i += 1) {
        if (values[`user_${clients[i].id}`]) {
          selected += 1;
        }
      }
      this.setState({
        select: selected === clients.length ? 'None' : 'All',
      });
    }
  };

  render() {
    const { initialValues = {}, onSubmit, showSubmissionError, pageSize = 25, title } = this.props;
    const { select, clients } = this.state;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col className="mb-3">Select which clients should participate in this challenge:</Col>
              </Row>
              <MaterialTable
                columns={[
                  {
                    title: 'Select',
                    render: (rowData) => (
                      <div className="d-flex align-items-center checkbox-row">
                        <Field
                          name={`user_${rowData.id}`}
                          component={renderCheckboxForField}
                          {...{
                            itemProps: {},
                            checkboxIcon: 'fa-check',
                            checkboxIconUnchecked: 'fa-close',
                            inputProps: {},
                          }}
                        />
                        <OnChange name={`user_${rowData.id}`}>
                          {(value) => {
                            this.checkValues(value, values, rowData.id);
                          }}
                        </OnChange>
                      </div>
                    ),
                    sorting: false,
                    width: null,
                    cellStyle: {
                      width: 100,
                      maxWidth: 100,
                    },
                  },
                  { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
                  { title: 'First Name', field: 'first_name' },
                  {
                    title: 'Client Status',
                    field: 'user_role_status',
                    render: (rowData) => (
                      <Pill
                        title={getFriendlyNameForUserStatus(rowData.user_role_status)}
                        className={getClassNameForSubscriptionStatus(rowData.user_role_status)}
                      />
                    ),
                  },
                ]}
                data={clients}
                title={title}
                key={clients.length} // https://github.com/mbrn/material-table/issues/1480
                options={{
                  padding: 'dense',
                  pageSize,
                  search: true,
                  paging: clients.length > pageSize,
                  sorting: true,
                  thirdSortClick: false,
                  showTitle: true,
                  toolbar: true,
                  pageSizeOptions: [pageSize, 50, 100],
                  debounceInterval: 600,
                  draggable: false,
                }}
                style={{
                  backgroundColor: '#f7f7f7',
                }}
                icons={tableIcons}
                onSearchChange={(x) => {
                  if (x !== '') {
                    const searchBar = document.querySelector('#mt-toolbar-container input');
                    if (!searchBar) return;
                    setTimeout(() => searchBar.focus());
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div id="mt-toolbar-container">
                      <MTableToolbar {...props} />
                      <div className="ml-3">
                        <span
                          className="span-link"
                          onClick={() => this.selectAllOrNone(form, values)}
                        >
                          Select {select}
                        </span>
                      </div>
                    </div>
                  ),
                }}
              />

              <Row className="mt-5">
                <Col xs={3}>
                  <Button color="primary" size="lg" type="submit" block>
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

ChallengeClientsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  clients: PropTypes.instanceOf(Array),
};

export default ChallengeClientsForm;
