import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';

class ButtonGroupNavigation extends Component {
  render() {
    const { buttons, onClick, activeButton } = this.props;
    return (
      <ButtonGroup>
        {buttons.map((button) => (
          <Button
            className={button.id === activeButton ? 'active' : ''}
            key={button.id}
            onClick={() => onClick(button)}
          >
            {button.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  }
}

ButtonGroupNavigation.propTypes = {
  buttons: PropTypes.instanceOf(Array),
  onClick: PropTypes.func,
  activeButton: PropTypes.string,
};

export default ButtonGroupNavigation;
