import moment from 'moment-timezone';

import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  SWITCH_TEAMS,
  AUTH_TOKEN_STORED,
  AUTH_USER_ID_RETRIEVED,
} from '../actions/authActions';
import { getActions } from '../actions/challengeActions';
import {
  SET_CUSTOM_MACROS,
  RESET_TARGETS,
  GET_USER_PROFILE,
  GET_PLAN,
  GET_MACRO_TARGETS,
  GET_DAILY_MACROS,
  GET_ALL_USER_MACROS,
} from '../actions/userActions';
import {
  USER_PROFILE_SUCCESS,
  UPDATE_USER_SUCCESS,
  GET_TARGETS_SUCCESS,
  WEIGH_INS_SUCCESS,
  BODY_FATS_SUCCESS,
  MEASUREMENTS_SUCCESS,
  GET_PLAN_SUCCESS,
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_FAILURE,
  GET_TARGETS_FAILURE,
  GET_DAILY_MACROS_SUCCESS,
  GET_ALL_USER_MACROS_SUCCESS,
  GET_ALL_USER_MACROS_FAILURE,
} from '../apiActions/userApiActions';
import { updateObjectAndSortArray, mergeAndDedupeArrays } from '../utils/arrayUtils';

const initialUserState = {};

export default (state = initialUserState, action) => {
  switch (action.type) {
    // case AUTH_LOGIN_SUCCESS:
    // case AUTH_TOKEN_STORED:
    // case AUTH_USER_ID_RETRIEVED:
    //   return {
    //     ...state,
    //     user_id: action.user_id,
    //   };
    case USER_PROFILE_SUCCESS: {
      // console.log('IN REDUCER WITH THIS USER_ID:', action.data.user_id);
      return { ...state, ...action.data };
    }
    case GET_USER_PROFILE: {
      // we're calling the API to get a new user so clear the one we may have in context
      // return null;
      return {
        user_id: action.user_id,
        targets: undefined,
      };
    }
    case GET_MACRO_TARGETS: {
      return {
        ...state,
        targets: undefined,
      };
    }
    case GET_ALL_USER_MACROS: {
      return {
        ...state,
        targets: undefined,
      };
    }
    case GET_DAILY_MACROS: {
      return {
        ...state,
        // dailyMacros: undefined,
      };
    }
    case GET_TARGETS_SUCCESS: {
      return { ...state, targets: action.data };
    }
    case GET_ALL_USER_MACROS_FAILURE: {
      return { ...state, targets: [] };
    }
    case GET_ALL_USER_MACROS_SUCCESS: {
      return {
        ...state,
        targets: action.data,
      };
    }
    case GET_DAILY_MACROS_SUCCESS: {
      const { dailyMacros = [] } = state;
      const newMacros = mergeAndDedupeArrays([dailyMacros, action.data]);
      return { ...state, dailyMacros: newMacros };
    }
    case GET_TARGETS_FAILURE: {
      return { ...state, targets: [] };
    }
    case SET_CUSTOM_MACROS: {
      // const { id, payload } = action;
      // // process payload into user.customTargets object
      // const customTargets = [];
      // Object.keys(payload).forEach((key) => {
      //   const keyArr = key.split('_');
      //   const dayType = keyArr[0];
      //   const macro = keyArr[1];
      //   const val = payload[key];
      //   const dayTypeTarget = customTargets.find((t) => t.dayType === dayType);
      //   if (!dayTypeTarget) {
      //     customTargets.push({
      //       dayType,
      //       [macro]: val,
      //     });
      //   } else {
      //     dayTypeTarget[macro] = val;
      //   }
      // });
      // return {
      //   ...state,
      //   customTargets,
      //   isCustomMacros: true,
      // };
      return {
        ...state,
        // targets: undefined,
      };
    }
    case RESET_TARGETS: {
      // const { id } = action;
      return {
        ...state,
        targets: undefined,
        // isCustomMacros: false,
      };
    }
    case WEIGH_INS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        weigh_ins: data,
      };
    }
    case BODY_FATS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        body_fats: data,
      };
    }
    case MEASUREMENTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        measurements: data,
      };
    }
    case GET_PLAN: {
      return {
        ...state,
        plan: null,
      };
    }
    case GET_PLAN_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        plan: data,
      };
    }
    case GET_MEMBERSHIP_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        membership: data,
      };
    }
    case GET_MEMBERSHIP_FAILURE: {
      const { error } = action;
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error === 'We could not find a membership for that user'
      ) {
        return {
          ...state,
          membership: 'NOT_FOUND',
        };
      }
      return {
        ...state,
      };
    }
    case AUTH_LOGOUT:
    case SWITCH_TEAMS: {
      return {
        ...initialUserState,
      };
    }
    default:
      return state;
  }
};
