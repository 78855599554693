import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Pill from '../Common/Pill';
import tableIcons from '../../utils/muiIcons';
import {
  capitalizeFirst,
  enumToSentenceCase,
  addLineBreaksForDisplay,
} from '../../utils/stringUtils';
import { renderActivityMetadata } from '../../utils/tableUtils';
import { formatDateWithTimezone } from '../../utils/dateUtils';
import { getClassNameForSubscriptionStatus } from '../../utils/subscriptionUtils';
import { generateSubscriptionStatus } from '../../utils/dataGeneratorUtils';

class ActivityTable extends Component {
  render() {
    const { activity, title = 'Activity', statusColumn = 'subscription_status' } = this.props;
    // temporarily set subscription status
    activity.forEach((t) => (t[statusColumn] = generateSubscriptionStatus()));
    return (
      <MaterialTable
        columns={[
          {
            title: 'Type',
            render: (rowData) => enumToSentenceCase(rowData.type),
            cellStyle: {
              width: 100,
              maxWidth: 100,
            },
          },
          {
            title: 'Details',
            render: (rowData) =>
              rowData.metadata ? addLineBreaksForDisplay(renderActivityMetadata(rowData)) : 'n/a',
            class: 'caption',
            cellStyle: {
              width: 280,
            },
          },
          {
            title: 'App Name',
            field: 'device.appName',
          },
          {
            title: 'App Version',
            field: 'device.appVersion',
            cellStyle: {
              width: 100,
              maxWidth: 100,
            },
          },
          {
            title: 'System',
            render: (rowData) => `${rowData.device.systemName} ${rowData.device.systemVersion}`,
            cellStyle: {
              width: 100,
              maxWidth: 120,
            },
          },
          {
            title: 'Device',
            field: 'device.deviceId',
            cellStyle: {
              width: 100,
              maxWidth: 120,
            },
          },
          {
            title: 'Activity Time',
            field: 'created_at',
            type: 'datetime',
            render: (rowData) =>
              formatDateWithTimezone(rowData.created_at, undefined, 'M/D/YY h:mm A z'),
          },
        ]}
        data={activity}
        title={title}
        options={{
          padding: 'dense',
          pageSize: activity.length < 10 ? activity.length : 10,
          search: false,
          paging: activity.length > 10,
          headerStyle: {
            fontSize: '11px',
          },
          thirdSortClick: false,
        }}
        style={{
          backgroundColor: '#f7f7f7',
          fontSize: '11px',
        }}
        icons={tableIcons}
      />
    );
  }
}

ActivityTable.propTypes = {
  title: PropTypes.string,
  onRowClick: PropTypes.func,
  activity: PropTypes.instanceOf(Object),
  statusColumn: PropTypes.string,
};

export default ActivityTable;
