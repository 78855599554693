import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class RecipeResult extends Component {
  render() {
    const { recipe } = this.props;
    const { photoUrl, id, name, count } = recipe;
    return (
      <Row className="recipe-result mb-3 mt-2">
        <Col className="d-flex flex-row">
          <img src={photoUrl} alt={name} />
          <div className="ml-2 d-flex flex-column">
            <Link to={`/recipes/${id}`}>{name}</Link>
            <div className="label">{`Logged ${count} time${count !== 1 ? 's' : ''} `}</div>
          </div>
        </Col>
      </Row>
    );
  }
}

RecipeResult.propTypes = {
  recipe: PropTypes.instanceOf(Object),
};

export default RecipeResult;
