import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PillHeader from '../Common/PillHeader';

class GoalLeaderboard extends Component {
  constructor(props) {
    super(props);
    const { leaderboard = [] } = this.props;
    this.state = {
      period: leaderboard.length ? leaderboard[0].period : '',
    };
  }

  render() {
    const { leaderboard = [], goal, lbsVerb } = this.props;
    let { period } = this.state;
    if (!period) {
      period = leaderboard.length ? leaderboard[0].period : '';
    }
    const periods = leaderboard.length ? leaderboard.map((l) => l.period) : [];
    const leaders = leaderboard.length
      ? leaderboard.find((l) => l.period === period).leaderboard
      : [];

    return (
      <div className="leaderboard">
        {leaders.length > 0 && (
          <>
            <PillHeader
              title={`${goal} Leaderboard`}
              dropdown={periods}
              activeDropdownItem={period}
              onChange={(b) => this.setState({ period: b })}
            />
            <Row className="mb-2 mt-3 leaderboard-header">
              <Col xs={6}>Client</Col>
              <Col xs={3}>Lbs {lbsVerb}</Col>
              <Col># of Weigh Ins</Col>
            </Row>
          </>
        )}
        {leaders.map((user, idx) => (
          <Row key={user.id}>
            <Col xs={6}>
              {`${idx + 1}. `}
              <Link to={{
                pathname: `/clients/${user.id}`,
                state: { from: 'Team Results' }
              }}
              >
                {user.name}
              </Link>
            </Col>
            <Col>{`${user.lbs} lb${user.lbs !== 1 ? 's' : ''}`}</Col>
            <Col>{user.weighIns}</Col>
          </Row>
        ))}
        {leaderboard.length === 0 && (
          <Row>
            <Col className="no-data-label">
              <div className="statistic text-center">No results yet.</div>
              <p>
                Once your Team starts using the Macrostax app, you'll see a summary of their results
                here.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

GoalLeaderboard.propTypes = {
  leaderboard: PropTypes.instanceOf(Object),
  goal: PropTypes.string,
  lbsVerb: PropTypes.string,
};

export default GoalLeaderboard;
