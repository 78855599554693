import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { firstBy, thenBy } from 'thenby';
import CardIcon from './CardIcon';
import DashboardLeaderboard from '../Leaderboards/DashboardLeaderboard';

class DashboardLeaderboardCard extends Component {
  render() {
    const { dashboard, team_id, isTeamOwner } = this.props;
    let { startDate, endDate } = this.props;
    const { events, users, weigh_ins, body_fats, green_thumbs } = dashboard;
    const tz = moment.tz.guess();
    // scope the dates without timestamps for easier math
    startDate = moment(startDate.format('YYYY-MM-DD'));
    endDate = moment(endDate.format('YYYY-MM-DD'));
    const days = Math.ceil(endDate.diff(startDate, 'days', true)) + 1;
    // console.log(`Viewing between ${startDate.format('YYYY-MM-DD')} and ${endDate.format('YYYY-MM-DD')} which is ${days} days`);

    // TODO: this should really be handled on the BE
    let leaderboard = [];
    for (let i = 0; i < users.length; i += 1) {
      const user = users[i];
      const { first_name, last_name, id } = user;
      const newUser = {
        name: `${first_name} ${last_name}`,
        id,
      };

      const daysActive = [];
      const daysLoggingFood = [];
      let lbs;
      let bodyFat;
      const possibleThumbs = days * 3;
      let greenThumbs = 0;
      let greenThumbsPct = 0;
      let carbs = 0;
      let carbsPct = 0;
      let protein = 0;
      let proteinPct = 0;
      let fat = 0;
      let fatPct = 0;
      let calories = 0;
      let caloriesPct = 0;

      const userEvents = events.filter((e) => e.user_id === user.id);
      for (let e = 0; e < userEvents.length; e += 1) {
        const event = userEvents[e];
        const { event_time, type } = event;
        const dt = moment(event_time).tz(tz);
        // console.log(`original: ${event.event_time}, converted: ${dt.toDate()}`);
        if (dt.isSameOrAfter(startDate, 'D') && dt.isSameOrBefore(endDate, 'D')) {
          const formatted = dt.format('YYYY-MM-DD');
          switch (type) {
            case 'NEW_SESSION':
              if (!daysActive.includes(formatted)) {
                daysActive.push(formatted);
              }
              break;
            // case 'PORTION_CREATED':
            //   if (!daysLoggingFood.includes(formatted)) {
            //     daysLoggingFood.push(formatted);
            //   }
            //   break;
          }
        }
      }

      const userWeighIns = weigh_ins.filter((e) => e.user_id === user.id);
      if (userWeighIns && userWeighIns.length >= 2) {
        let weighIns = [];
        for (let w = 0; w < userWeighIns.length; w += 1) {
          const weighIn = userWeighIns[w];
          const { date, weight } = weighIn;
          const dt = moment(date).tz(tz);
          if (dt.isSameOrAfter(startDate, 'D') && dt.isSameOrBefore(endDate, 'D')) {
            const formatted = dt.format('YYYY-MM-DD');
            weighIns.push({
              date: formatted,
              weight,
            });
          }
        }
        if (weighIns.length >= 2) {
          weighIns = weighIns.sort(firstBy('date').thenBy('weight'));
          lbs = (weighIns[0].weight - weighIns[weighIns.length - 1].weight) * -1;
          newUser.startingWeight = weighIns[0].weight;
          newUser.endingWeight = weighIns[weighIns.length - 1].weight;
        }
      }

      const userBodyFats = body_fats ? body_fats.filter((e) => e.user_id === user.id) : undefined;
      if (userBodyFats && userBodyFats.length >= 2) {
        let bodyFats = [];
        for (let w = 0; w < userBodyFats.length; w += 1) {
          const weighIn = userBodyFats[w];
          const { date, body_fat } = weighIn;
          const dt = moment(date).tz(tz);
          if (dt.isSameOrAfter(startDate, 'D') && dt.isSameOrBefore(endDate, 'D')) {
            const formatted = dt.format('YYYY-MM-DD');
            bodyFats.push({
              date: formatted,
              body_fat,
            });
          }
        }
        if (bodyFats.length >= 2) {
          bodyFats = bodyFats.sort(firstBy('date').thenBy('body_fat'));
          bodyFat = (bodyFats[0].body_fat - bodyFats[bodyFats.length - 1].body_fat) * -1;
          newUser.startingBodyFat = bodyFats[0].body_fat;
          newUser.endingBodyFat = bodyFats[bodyFats.length - 1].body_fat;
        }
      }

      const userGreenThumbs = green_thumbs ? green_thumbs.filter((e) => e.user_id === user.id) : undefined;
      if (userGreenThumbs && userGreenThumbs.length) {
        // this will give us both green thumbs compliance and unique days logging food
        for (let g = 0; g < userGreenThumbs.length; g += 1) {
          const greenThumb = userGreenThumbs[g];
          const { macros_date, carbs_green_thumb, protein_green_thumb, fat_green_thumb, calories_total, calories_target = 0 } = greenThumb;
          const dt = moment(macros_date).tz(tz);
          if (dt.isSameOrAfter(startDate, 'D') && dt.isSameOrBefore(endDate, 'D')) {
            if (calories_total !== undefined && calories_total > 0) {
              daysLoggingFood.push(dt);
            }
            if (carbs_green_thumb) {
              greenThumbs += 1;
              carbs += 1;
            }
            if (protein_green_thumb) {
              greenThumbs += 1;
              protein += 1;
            }
            if (fat_green_thumb) {
              greenThumbs += 1;
              fat += 1;
            }
            if (Math.abs(calories_total - calories_target) <= 100) {
              calories += 1;
            }
          }
        }
      }

      if (possibleThumbs > 0) {
        greenThumbsPct = (greenThumbs / possibleThumbs) * 100;
        carbsPct = (carbs / days) * 100;
        proteinPct = (protein / days) * 100;
        fatPct = (fat / days) * 100;
        caloriesPct = (calories / days) * 100;
      } else {
        possibleThumbs = 0;
      }

      newUser.daysActive = daysActive.length;
      newUser.daysLoggingFood = daysLoggingFood.length;
      newUser.lbs = lbs;
      newUser.bodyFat = bodyFat;
      newUser.greenThumbsPct = greenThumbsPct;
      newUser.greenThumbs = greenThumbs;
      newUser.possibleThumbs = possibleThumbs;
      newUser.carbs = carbs;
      newUser.carbsPct = carbsPct;
      newUser.protein = protein;
      newUser.proteinPct = proteinPct;
      newUser.fat = fat;
      newUser.fatPct = fatPct;
      newUser.calories = calories;
      newUser.caloriesPct = caloriesPct;
      newUser.days = days;

      leaderboard.push(newUser);
    }

    if (leaderboard.length) {
      leaderboard = leaderboard.sort(
        firstBy('daysActive', 'desc')
        .thenBy('daysLoggingFood', 'desc')
        .thenBy('name')
        .thenBy('lbs')
        .thenBy('bodyFat')
        .thenBy('greenThumbs')
        .thenBy('protein')
        .thenBy('carbs')
        .thenBy('fat')
        .thenBy('calories')
      );
    }

    return (
      <Card className="card-default card-container mb-3 green-thumbs-card flex-grow-1">
        <CardHeader>
          Client Engagement Summary
          {/* <CardIcon helpText="DASHBOARD_LEADERBOARD" /> */}
        </CardHeader>
        <CardBody>
          <DashboardLeaderboard leaderboard={leaderboard} isTeamOwner={isTeamOwner} team_id={team_id} />
        </CardBody>
      </Card>
    );
  }
}

DashboardLeaderboardCard.propTypes = {
  dashboard: PropTypes.instanceOf(Object),
  isTeamOwner: PropTypes.bool,
  team_id: PropTypes.string,
  startDate: PropTypes.instanceOf(Object),
  endDate: PropTypes.instanceOf(Object),
};

export default DashboardLeaderboardCard;
