import React, { Component, forwardRef } from 'react';
import { Row, Col, CardDeck } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Loading from '../../components/Common/Loading';
import StatisticCard from '../../components/Cards/StatisticCard';
import GreenThumbsLeaderboardCard from '../../components/Cards/GreenThumbsLeaderboardCard';
import DashboardLeaderboardCard from '../../components/Cards/DashboardLeaderboardCard';
import DashboardLeaderboardCardAlt from '../../components/Cards/DashboardLeaderboardCardAlt';
import GreenThumbsCard from '../../components/Cards/GreenThumbsCard';
import GoalDistributionCard from '../../components/Cards/GoalDistributionCard';
import NutritionStatsCard from '../../components/Cards/NutritionStatsCard';
import TeamResultsCard from '../../components/Cards/TeamResultsCard';
import ClientListModal from '../../modals/ClientListModal';
import { shuffleArray } from '../../utils/arrayUtils';

class TeamDashboardContainer extends Component {
  constructor(props) {
    // console.log('in constructor', props);
    super(props);
    const now = moment();
    const { clients, startDate = now.clone().add(-30, 'days'), endDate = now } = props;
    this.state = {
      clientListModalOpen: false,
      filteredClients: clients,
      filteredClientsType: '',
      startDate,
      endDate,
      summarySortBy: 'days_active',
      summaryOrder: 'asc',
      summaryShowCount: 25,
      summaryPage: 1,
    };
  }

  toggleClientListModal = () => {
    const { clientListModalOpen } = this.state;
    this.setState({
      clientListModalOpen: !clientListModalOpen,
    });
  };

  filterClients = (type) => {
    const { clients, team } = this.props;
    const { powerUsers, inactiveUsers } = team;
    // TODO: this is just simulating a subset of users based on the counts above
    const shuffled = shuffleArray(clients);
    const count = type === 'Power' ? powerUsers : inactiveUsers;
    this.setState(
      {
        filteredClients: shuffled.slice(0, count),
        filteredClientsType: type,
      },
      () => this.toggleClientListModal()
    );
  };

  setDates = (whichDate, val) => {
    let {
      startDate,
      endDate,
      summarySortBy,
      summaryOrder,
      summaryPage,
      summaryShowCount,
    } = this.state;
    const { team, doGetEngagementSummary } = this.props;
    const { team_id } = team;
    const m = moment(val);
    if (whichDate === startDate) {
      startDate = m;
      if (m.isAfter(endDate)) {
        endDate = startDate.clone().add(1, 'days');
      }
    } else {
      endDate = m;
      if (m.isBefore(startDate)) {
        startDate = endDate.clone().add(-1, 'days');
      }
    }

    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        doGetEngagementSummary(
          team_id,
          startDate,
          endDate,
          summarySortBy,
          summaryOrder,
          summaryPage,
          summaryShowCount
        );
      }
    );
  };

  updateSortOrder = (sortBy, order, page, showCount) => {
    let { startDate, endDate } = this.state;
    const { team, doGetEngagementSummary } = this.props;
    const { team_id } = team;

    this.setState(
      {
        startDate,
        endDate,
        summarySortBy: sortBy,
        summaryOrder: order,
        summaryPage: page,
        summaryShowCount: showCount,
      },
      () => {
        doGetEngagementSummary(team_id, startDate, endDate, sortBy, order, page, showCount, false);
      }
    );
  };

  render() {
    const { team, timestamp, isTeamOwner, doGetEngagementSummaryCsv, doClearEngagementSummaryCsv } = this.props;
    const {
      totalClients,
      logins,
      loginsBy,
      powerUsers,
      inactiveUsers,
      nutritionStats,
      teamResults,
      greenThumbsLeaderboard,
      membership,
      dashboard,
      engagementSummary,
      team_id,
      counts,
      goal_distribution,
      exportData,
      exportHeaders,
    } = team;
    // const { allTimeGreenThumbs, allTimeGreenThumbsBy } = nutritionStats;
    const { active_clients, max_clients } = membership;
    // const { users, counts, goal_distribution } = dashboard;
    const {
      clientListModalOpen,
      filteredClients,
      filteredClientsType,
      startDate,
      endDate,
      summaryOrder,
      summaryPage,
    } = this.state;

    let activeClients = 0;
    let pendingInvites = 0;
    let pendingInvitations = 0;

    const pendingObj = counts.find((c) => c.clients_pending_invite !== undefined);
    if (pendingObj) {
      pendingInvitations = pendingObj.clients_pending_invite;
    }

    let remainingClients = max_clients - active_clients;
    if (remainingClients < 0) {
      remainingClients = 0;
    }

    // const startDate = moment().add(-30, 'days');
    // const endDate = moment();
    const dateFormat = 'MMMM D, YYYY';

    const DateInput = forwardRef(({ value, onClick }, ref) => (
      // <button className="planner-date-input" onClick={onClick} ref={ref}>
      //   <em className="far fa-calendar-alt" /> {value} - {DayTypes[day_type]}
      // </button>
      <span className="nls-input" onClick={onClick} ref={ref}>
        {value}
      </span>
    ));

    return (
      <>
        <Row className="d-flex justify-content-between">
          {/* <Col lg={4}>
            <StatisticCard
              header="Active Clients"
              statistic={active_clients.toString()}
              caption={`You have ${remainingClients} spot${
                remainingClients !== 1 ? 's' : ''
              } remaining*`}
            />
            <StatisticCard
              header="Pending Client Invitations"
              statistic={pendingInvitations.toString()}
            />
            <GoalDistributionCard goalDistribution={goal_distribution} /> */}
          {/* <CardDeck className="h-100">
              <StatisticCard
                header="Active Clients"
                statistic={active_clients.toString()}
                caption={`${remainingClients} membership${remainingClients !== 1 ? 's' : ''} remaining`}
              />
              <StatisticCard
                header="Pending Invitations"
                statistic={pendingInvitations.toString()}
              />
              <StatisticCard
                header="# Log ins, last 30 days"
                statistic={50}
                // statistic={logins.toString()}
                caption={`From ${loginsBy} clients`}
              />
              <StatisticCard
                header="Power Clients"
                statistic={powerUsers.toString()}
                showHelp
                helpText="POWER_CLIENTS"
                caption="View Details"
                onCaptionClick={() => this.filterClients('Power')}
              />
              <StatisticCard
                header="Inactive Clients"
                statistic={inactiveUsers.toString()}
                showHelp
                helpText="INACTIVE_CLIENTS"
                caption="View Details"
                onCaptionClick={() => this.filterClients('Inactive')}
              />
    </CardDeck> */}
          {/* </Col> */}
          <Col>
            {/* <h4 className="mb-4">
              Viewing engagement activity between{' '}
              <span className="nls-input">{startDate.format(dateFormat)}</span> and{' '}
              <span className="nls-input">{endDate.format(dateFormat)}</span>
            </h4> */}
            <div className="mb-4 d-flex flex-row dashboard-dates-wrapper">
              <div className="mr-1">Viewing engagement activity between </div>
              <DatePicker
                selected={startDate.toDate()}
                onChange={(val) => this.setDates(startDate, val)}
                dateFormat="MMMM d, yyyy"
                showMonthDropdown
                showYearDropdown
                customInput={<DateInput />}
              />
              <div className="ml-1 mr-1">and </div>
              <DatePicker
                selected={endDate.toDate()}
                onChange={(val) => this.setDates(endDate, val)}
                dateFormat="MMMM d, yyyy"
                showMonthDropdown
                showYearDropdown
                customInput={<DateInput />}
              />
            </div>

            {/* <DashboardLeaderboardCard
              dashboard={dashboard}
              team_id={team_id}
              isTeamOwner={isTeamOwner}
              startDate={startDate}
              endDate={endDate}
            /> */}
            {engagementSummary ? (
              <DashboardLeaderboardCardAlt
                engagementSummary={engagementSummary}
                team_id={team_id}
                isTeamOwner={isTeamOwner}
                startDate={startDate}
                endDate={endDate}
                updateSortOrder={this.updateSortOrder}
                getEngagementSummaryCsv={doGetEngagementSummaryCsv}
                clearEngagementSummaryCsv={doClearEngagementSummaryCsv}
                exportData={exportData}
                exportHeaders={exportHeaders}
              />
            ) : (
              <Loading />
            )}
          </Col>
          {/* <Col lg={4} className="d-flex mr-0 pr-0">
            <GreenThumbsLeaderboardCard greenThumbsLeaderboard={greenThumbsLeaderboard} />
          </Col> */}
        </Row>
        <Row>
          <Col className="no-flex-on-mobile">
            <StatisticCard
              header="Active Clients"
              statistic={active_clients.toString()}
              caption={`You have ${remainingClients} spot${
                remainingClients !== 1 ? 's' : ''
              } remaining*`}
            />
            <StatisticCard
              header="Pending Client Invitations"
              className="mr-4 ml-4 no-margin-mobile"
              statistic={pendingInvitations.toString()}
            />
            <GoalDistributionCard goalDistribution={goal_distribution} />
          </Col>
          {/* <Col xs={4}><StatisticCard
              header="Active Clients"
              statistic={active_clients.toString()}
              caption={`You have ${remainingClients} spot${
                remainingClients !== 1 ? 's' : ''
              } remaining*`}
            /></Col>
          <Col xs={4}><StatisticCard
              header="Pending Client Invitations"
              statistic={pendingInvitations.toString()}
            /></Col>
          <Col xs={4}><GoalDistributionCard goalDistribution={goal_distribution} /></Col> */}
        </Row>
        {/* <Row className="d-flex justify-content-between">
          <Col lg={4} className="d-flex mr-0 pr-0">
            <GreenThumbsLeaderboardCard greenThumbsLeaderboard={greenThumbsLeaderboard} />
          </Col>
          <Col lg={4} className="d-flex mr-0 pr-0">
            <GreenThumbsCard
              allTimeGreenThumbs={allTimeGreenThumbs}
              allTimeGreenThumbsBy={allTimeGreenThumbsBy}
            />
          </Col>
          <Col lg={4} className="d-flex mr-0 pr-0">
            <GoalDistributionCard teamResults={teamResults} />
          </Col>
        </Row> */}
        {/* <Row>
          <Col xs={12}>
            <CardDeck>
              <NutritionStatsCard nutritionStats={nutritionStats} />
              <div>
                <GreenThumbsCard
                  allTimeGreenThumbs={allTimeGreenThumbs}
                  allTimeGreenThumbsBy={allTimeGreenThumbsBy}
                />
                <GreenThumbsLeaderboardCard greenThumbsLeaderboard={greenThumbsLeaderboard} />
                <GoalDistributionCard teamResults={teamResults} />
              </div>
              <TeamResultsCard teamResults={teamResults} timestamp={timestamp} />
            </CardDeck>
          </Col>
        </Row> */}
        {/* <ClientListModal
          team={team}
          isOpen={clientListModalOpen}
          toggle={this.toggleClientListModal}
          users={filteredClients}
          type={filteredClientsType}
        /> */}
        <Row>
          <Col className="card-caption">
            *If you exceed your tier's number of active clients we will automatically upgrade your
            plan on your renewal date.
          </Col>
        </Row>
      </>
    );
  }
}

TeamDashboardContainer.propTypes = {
  team: PropTypes.instanceOf(Object),
  timestamp: PropTypes.number,
  clients: PropTypes.instanceOf(Object),
  isTeamOwner: PropTypes.bool,
  doGetEngagementSummary: PropTypes.func,
  doGetEngagementSummaryCsv: PropTypes.func,
  doClearEngagementSummaryCsv: PropTypes.func,
};

const mapStateToProps = (state) => ({
  clients: state.clients,
});
// const mapDispatchToProps = (dispatch) => ({});
const mapDispatchToProps = (dispatch) => ({
  updateSortOrder: (sortBy, order, page, showCount) =>
    dispatch(updateSortOrder(sortBy, order, page, showCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDashboardContainer);
