import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimatedNumber from 'react-animated-numbers';
import ReactTextTransition, { presets } from 'react-text-transition';
import { Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import { capitalizeFirst } from '../../utils/stringUtils';
import CardIcon from '../../components/Cards/CardIcon';
import Loading from '../../components/Common/Loading';
import GoalFormModal from '../../modals/GoalFormModal';
import { formatGoal, formatPhase, formatActivityLevel } from '../../utils/teamProfileUtils';
import { DayTypes } from '../../enums/dayTypes';
const macros = ['carbs', 'protein', 'fat'];

class MacroTargetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goalFormModalOpen: false,
    };
  }

  toggleGoalFormModal = () => {
    const { goalFormModalOpen } = this.state;
    this.setState({
      goalFormModalOpen: !goalFormModalOpen,
    });
  };

  render() {
    const {
      user,
      render,
      showGoal,
      teamProfile,
      showEditGoal = false,
      teamName,
      team_id,
    } = this.props;
    const { user_id, targets, first_name, last_name, isCustomMacros } = user;
    const { goalFormModalOpen } = this.state;
    let plans = [];
    let goal_id;
    let phase_id;
    let activity_level_id;

    let title = 'Macro Targets';
    if (targets && targets.macrostax) {
      ({ goal_id, activity_level_id, phase_id } = targets);
      title += ` - ${formatGoal(teamProfile, goal_id, true)} / ${formatPhase(
        teamProfile,
        phase_id,
        true
      )}, Activity ${capitalizeFirst(formatActivityLevel(teamProfile, activity_level_id))}`;
    }

    plans =
      isCustomMacros && targets && targets.custom && targets.custom.length
        ? targets.custom
        : targets && targets.macrostax && targets.macrostax.length
        ? targets.macrostax
        : [];
    return (
      <>
        {render && (
          <>
            {plans && plans.length ? (
              <Card className={`card-default card-container mb-0 large-header-card flex-grow-1`}>
                <CardHeader className="d-flex justify-space-between align-items-center animated">
                  <div>
                    {/* <ReactTextTransition
                      text={title}
                      springConfig={presets.stiff}
                      inline
                      noOverflow
                    /> */}
                    Macro Targets
                    {goal_id && phase_id && activity_level_id && showEditGoal && (
                      <>
                        {' - '}
                        <span
                          className="span-link font-size-one"
                          onClick={this.toggleGoalFormModal}
                        >
                          {formatGoal(teamProfile, goal_id, true)} /{' '}
                          {formatPhase(teamProfile, phase_id, true)}, Activity{' '}
                          {capitalizeFirst(formatActivityLevel(teamProfile, activity_level_id))}
                        </span>
                      </>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={3} className="strong p-2">
                      Day Type
                    </Col>
                    <Col xs={3} className="text-center strong border-left p-2">
                      Carbs
                    </Col>
                    <Col xs={3} className="text-center strong border-left p-2">
                      Protein
                    </Col>
                    <Col xs={3} className="text-center strong border-left p-2">
                      Fat
                    </Col>
                  </Row>
                  {plans.map((target) => (
                    <Row className="border-top animated row" key={target.day_type_id}>
                      <Col
                        xs={3}
                        className="p-2 align-self-center animated day-type-container col-3"
                        // className="p-2 align-self-center animated d-flex flex-row day-type-container col-3"
                      >
                        <div>{capitalizeFirst(DayTypes[target.day_type_id])}</div>
                        {/* <div className="ml-1 mr-1">-</div> */}
                        {/* <div className="d-flex justify-content-center caption"> */}
                        <div className="caption">{target.calories} kcal</div>
                      </Col>
                      <Col xs={3} className="text-center border-left p-2 col-3">
                        {target.carbs}g
                      </Col>
                      <Col xs={3} className="text-center border-left p-2 col-3">
                        {target.protein}g
                      </Col>
                      <Col xs={3} className="text-center border-left p-2 col-3">
                        {target.fat}g
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            ) : targets && !targets.length ? (
              <Card className="card-default card-container mb-3 line-chart-card flex-grow-1">
                <CardHeader className="d-flex justify-space-between align-items-center"></CardHeader>
                <CardBody>
                  <div className="no-results-container text-center mb-4">
                    <h3>No macro targets yet.</h3>
                    <div className="mb-3">
                      This client has not had macro targets calculated yet.
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Loading />
            )}
          </>
        )}
        {goal_id && phase_id && activity_level_id && showEditGoal && (
          <GoalFormModal
            user={user}
            isOpen={goalFormModalOpen}
            toggle={this.toggleGoalFormModal}
            teamName={teamName}
            teamProfile={teamProfile}
            goal_id={goal_id}
            phase_id={phase_id}
            activity_level_id={activity_level_id}
            team_id={team_id}
          />
        )}
      </>
    );
  }
}

MacroTargetsTable.propTypes = {
  user: PropTypes.instanceOf(Object),
  render: PropTypes.bool,
  showGoal: PropTypes.bool,
  teamProfile: PropTypes.instanceOf(Object),
  showEditGoal: PropTypes.bool,
  teamName: PropTypes.string,
  team_id: PropTypes.string,
};

export default MacroTargetsTable;
