import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

class PillHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ddOpen: false,
    };
  }

  toggleDD = () => {
    const { ddOpen } = this.state;
    this.setState({
      ddOpen: !ddOpen,
    });
  };

  render() {
    const { title, dropdown = [], activeDropdownItem, onChange } = this.props;
    const { ddOpen } = this.state;
    return (
      <div className="pill-header-container d-flex justify-content-between align-items-center">
        <div>{title}</div>
        {dropdown.length > 0 && (
          <ButtonToolbar>
            <ButtonDropdown isOpen={ddOpen} toggle={() => this.toggleDD()}>
              <DropdownToggle caret>{activeDropdownItem}</DropdownToggle>
              <DropdownMenu>
                {dropdown.map((d) => (
                  <DropdownItem key={d} onClick={() => onChange(d)}>
                    {d}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonToolbar>
        )}
      </div>
    );
  }
}

PillHeader.propTypes = {
  title: PropTypes.string,
  dropdown: PropTypes.instanceOf(Array),
  activeDropdownItem: PropTypes.string,
  onChange: PropTypes.func,
};

export default PillHeader;
