import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button, Row, Col } from 'reactstrap';
import ReactGA from 'react-ga4';
import PageTitle from '../components/Common/PageTitle';
import ENV_VARS from '../utils/envVars';

class OnboardingModal extends Component {
  constructor(props) {
    super(props);

    const panels = [
      {
        title: 'Welcome!',
        img: 'https://via.placeholder.com/560x290?text=Dashboard+placeholder+image+here',
        buttonText: 'Get Started',
        description:
          `You've started your ${ENV_VARS.TRIAL_DAYS}-day FREE trial! Ready to get your business on board with Macrostax?`,
      },
      {
        title: 'Invite Clients',
        img: 'https://via.placeholder.com/560x290?text=Dashboard+placeholder+image+here',
        buttonText: 'Next',
        description:
          'Invite your clients to start using the Macrostax app where they can find custom meal plans, track their meals, and log progress metrics.',
      },
      {
        title: 'See Results',
        img: 'https://via.placeholder.com/560x290?text=Dashboard+placeholder+image+here',
        buttonText: 'Next',
        description:
          'View client stats and progress as they use the app so you can monitor progress and see how Macrostax adds value to your service offerings.',
      },
      {
        title: 'Send Communication',
        img: 'https://via.placeholder.com/560x290?text=Dashboard+placeholder+image+here',
        buttonText: "Let's go!",
        description:
          "Utilize Macrostax Team's pre-made templates to cheer on, support, and check in on your clients!",
      },
    ];

    const initialState = {
      activePanelIdx: 0,
      panels,
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  resetState = () => {
    const { initialState } = this.state;
    this.setState({
      ...initialState,
      initialState,
    });
  };

  panelClick = () => {
    const { activePanelIdx, panels } = this.state;
    const { toggle, postAction } = this.props;
    if (activePanelIdx === panels.length - 1) {
      toggle();
      if (postAction) {
        postAction();
      }
      setTimeout(() => this.resetState(), 500);
    } else {
      this.setState({
        activePanelIdx: activePanelIdx + 1,
      });
    }
  };

  render() {
    const { isOpen } = this.props;
    const { activePanelIdx, panels } = this.state;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/onboarding', title: 'Onboarding' });
    }

    const activePanel = panels[activePanelIdx];

    return (
      <Modal isOpen={isOpen} className="onboarding-modal" unmountOnClose>
        <ModalBody>
          {/* <Row className="modal-header">
            {panels.map((p, idx) => (
              <Col xs={3} className={activePanelIdx === idx ? 'active' : ''} key={`panel_${idx}`}>
                {p.title}
              </Col>
            ))}
          </Row> */}
          <Row className="content">
            <Col className="text-center">
              <img src={activePanel.img} alt="Placeholder" />
              <p className="mt-4 mb-4">{activePanel.description}</p>
              <Button
                color="primary"
                size="lg"
                className="fixed-width-lg"
                onClick={this.panelClick}
              >
                {activePanel.buttonText}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  postAction: PropTypes.func,
};

export default OnboardingModal;
