import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Row, Col, Button } from 'reactstrap';
import { formatDateToIso8601 } from '../utils/dateUtils';
import Loading from '../components/Common/Loading';
import MacrostaxLinksFormPartial from './partials/MacrostaxLinksFormPartial';
import DeleteBodyFatModal from '../modals/DeleteBodyFatModal';
import { submissionError } from '../actions/formActions';
import { addBodyFat, updateBodyFat, deleteBodyFat } from '../actions/userActions';
import { addHiddenFeature, deleteHiddenFeature } from '../actions/teamActions';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';

const validate = (values) => {
  const errors = {};

  return errors;
};

class ContentForm extends Component {
  render() {
    const {
      showSubmissionError,
      hiddenFeatures,
      initialValues,
      onSubmit,
      team,
      doAddHiddenFeature,
      doDeleteHiddenFeature,
    } = this.props;
    return (
      <>
        {hiddenFeatures !== undefined && team !== undefined && team.settings !== undefined ? (
          <Form
            onSubmit={(values) => {
              const errors = validate(values);
              if (Object.keys(errors).length === 0) {
                onSubmit(values);
              } else {
                showSubmissionError(errors);
              }
            }}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, form, ...props }) => {
              return (
                <form onSubmit={handleSubmit} className="inline-form">
                  <MacrostaxLinksFormPartial
                    hiddenFeatures={hiddenFeatures}
                    team={team}
                    doAddHiddenFeature={doAddHiddenFeature}
                    doDeleteHiddenFeature={doDeleteHiddenFeature}
                  />
                </form>
              );
            }}
          />
        ) : (
          <Loading />
        )}

        {/* {body_fats.length > 0 && (
          <Row>
            <Col xs={4} className="strong p-2">
              Date
            </Col>
            <Col xs={4} className="text-center strong border-left p-2">
              Body Fat %
            </Col>
            <Col xs={4} className="text-center strong border-left p-2">
              Actions
            </Col>
          </Row>
        )} */}
        {/* {body_fats.map((w, idx) => (
          <Form
            key={`${idx}-${w.date}-${w.body_fat}`}
            onSubmit={(values) => {
              const errors = validate(values, body_fats);
              if (Object.keys(errors).length === 0) {
                this.saveBodyFat(values);
              } else {
                showSubmissionError(errors, false);
              }
            }}
            initialValues={{
              body_fat: w.body_fat,
              date: moment(w.date).toDate(),
              id: w.id,
            }}
            keepDirtyOnReinitialize={false}
            render={({ handleSubmit, submitting, form, values }) => (
              <form onSubmit={handleSubmit} className="weigh-ins-form">
                <Row className="border-top">
                  <Col xs={4} className="p-2 align-self-center">
                    {w.id == undefined ? (
                      `${moment(w.date).tz(timezone).format('MMMM D, YYYY')}`
                    ) : (
                      <Field
                        name="date"
                        component={renderDatepickerForField}
                        className="text-left"
                        {...{
                          itemProps: {},
                          inputProps: {
                            maxDate: moment().toDate(),
                          },
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    xs={4}
                    className="text-center border-left d-flex align-items-center justify-content-center"
                  >
                    {w.status !== 'edit' ? (
                      `${w.body_fat}`
                    ) : (
                      <div className="animated">
                        <Field
                          name="body_fat"
                          component={renderInputForField}
                          stackedlabel={false}
                          parse={(value) =>
                            value ? parseFloat(Number.parseFloat(value).toFixed(2)).toString() : ''
                          }
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="text-center border-left p-2">
                    {w.status !== 'edit' ? (
                      <div className="d-flex justify-content-around animated">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.editBodyFat(w, 'edit');
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleDeleteBodyFatModal(w.date);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-around animated">
                        <Button color="primary">Save</Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.cancelEdit(w);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </form>
            )}
          />
        ))} */}
        {/* <Row className="border-top pt-3">
          <Col>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.addBodyFat();
              }}
              disabled={this.state.body_fats.filter((w) => w.id != undefined).length > 0}
            >
              Add Body Fat Measurement
            </Button>
          </Col>
        </Row> */}
      </>
    );
  }
}

ContentForm.propTypes = {
  customFeatures: PropTypes.instanceOf(Array),
  hiddenFeatures: PropTypes.instanceOf(Array),
  doUpdateFeatures: PropTypes.func,
  showSubmissionError: PropTypes.func,
  team: PropTypes.instanceOf(Object),
  doAddHiddenFeature: PropTypes.func,
  doDeleteHiddenFeature: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
  doAddBodyFat: (id, values) => dispatch(addBodyFat(id, values)),
  doUpdateBodyFat: (id, values) => dispatch(updateBodyFat(id, values)),
  doAddHiddenFeature: (id) => dispatch(addHiddenFeature(id)),
  doDeleteHiddenFeature: (id) => dispatch(deleteHiddenFeature(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentForm);
