import ENV_VARS from '../utils/envVars';

export const GET_INTEGRATIONS_SUCCESS = 'GET_INTEGRATIONS_SUCCESS';
export const GET_INTEGRATIONS_FAILURE = 'GET_INTEGRATIONS_FAILURE';

export const GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS = 'GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS';
export const GET_ALL_LOCATIONS_FROM_INTEGRATION_FAILURE = 'GET_ALL_LOCATIONS_FROM_INTEGRATION_FAILURE';

export const GET_IMPORT_STATUS_SUCCESS = 'GET_IMPORT_STATUS_SUCCESS';
export const GET_IMPORT_STATUS_FAILURE = 'GET_IMPORT_STATUS_FAILURE';

export const SETUP_INTEGRATION_SUCCESS = 'SETUP_INTEGRATION_SUCCESS';
export const SETUP_INTEGRATION_FAILURE = 'SETUP_INTEGRATION_FAILURE';

export const IMPORT_USER_SUCCESS = 'IMPORT_USER_SUCCESS';
export const IMPORT_USER_FAILURE = 'IMPORT_USER_FAILURE';

export const CANCEL_IMPORT_USER_SUCCESS = 'CANCEL_IMPORT_USER_SUCCESS';
export const CANCEL_IMPORT_USER_FAILURE = 'CANCEL_IMPORT_USER_FAILURE';

export function getIntegrations() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/integrations',
    method: 'GET',
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_INTEGRATIONS_SUCCESS,
    failureAction: GET_INTEGRATIONS_FAILURE,
  };
}

export function getLocationsIntegration(integrationId) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/integrations/${integrationId}/locations`,
    method: 'GET',
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS,
    failureAction: GET_ALL_LOCATIONS_FROM_INTEGRATION_FAILURE,
  };
}

export function getImportStatus(integrationId) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/integrations/${integrationId}/import_status`,
    method: 'GET',
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: false,
    successAction: GET_IMPORT_STATUS_SUCCESS,
    failureAction: GET_IMPORT_STATUS_FAILURE,
  };
}

export function setupIntegration(values, integrationId) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/integrations/${integrationId}`,
    method: 'PUT',
    data: values,
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SETUP_INTEGRATION_SUCCESS,
    failureAction: SETUP_INTEGRATION_FAILURE,
  };
}

export function importUsers(values, integrationId) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/integrations/${integrationId}/import`,
    method: 'POST',
    data: values,
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: IMPORT_USER_SUCCESS,
    failureAction: IMPORT_USER_FAILURE,
  };
}

export function cancelImportUsers(integrationId) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: `/integrations/${integrationId}/import`,
    method: 'DELETE',
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: CANCEL_IMPORT_USER_SUCCESS,
    failureAction: CANCEL_IMPORT_USER_FAILURE,
  };
}
