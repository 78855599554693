import { Roles } from './enums/roles';

// defining the different routes here so that the auth utils can also reference them more easily
export const AuthMenu = {
  dashboard: {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'icon-grid',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  clients: {
    name: 'Clients',
    icon: 'icon-user',
    // label: { value: 1, color: 'info' },
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  clientList: {
    name: 'Client Search',
    path: '/clients',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  addClients: {
    name: 'Add Clients',
    path: '/addclients',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  userDetail: {
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN, Roles.INTERNAL_ADMIN],
  },
  messaging: {
    name: 'Messaging',
    icon: 'far fa-comment',
    // label: { value: 1, color: 'info' },
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  messages: {
    name: 'Message List',
    path: '/messages',
    // label: { value: 1, color: 'info' },
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  // referralprogram: {
  //   name: 'Referral Program',
  //   icon: 'fas fa-bullhorn',
  //   roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  // },
  // referrals: {
  //   name: 'Referral Link',
  //   path: '/referrals',
  //   roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  // },
  challenges: {
    name: 'Challenges',
    icon: 'icon-trophy',
    // label: { value: 1, color: 'info' },
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  challengesList: {
    name: 'View Challenges',
    path: '/challenges',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  addChallenge: {
    name: 'Add a Challenge',
    path: '/addchallenge',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  challengeGuide: {
    name: 'Nutrition Challenge Guide',
    path: 'https://macrostax-production.s3.us-east-2.amazonaws.com/static/How+to+Run+a+Nutrition+Challenge+for+Macrostax+Team+Users.pdf',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
    external: true,
  },
  challengeClients: {
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  groups: {
    name: 'Groups',
    path: '/groups',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  settings: {
    name: 'Settings',
    icon: 'icon-settings',
  },
  accountSettings: {
    name: 'My Account Settings',
    path: '/settings',
  },
  businessSettings: {
    name: 'Business Settings',
    path: '/businesssettings',
    roles: [Roles.TEAM_OWNER],
  },
  integrations: {
    name: 'Integrations',
    path: '/integrations',
    roles: [Roles.TEAM_OWNER],
  },
  content: {
    name: 'App Content',
    // path: '/contentconfig',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
    icon: 'icon-folder-alt',
  },
  menuLinks: {
    name: 'Customize Links',
    path: '/menulinks',
  },
  homeContent: {
    name: 'Home Screen Content',
    path: '/homecontent',
  },
  inbox: {
    path: '/inbox',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  adminUsers: {
    name: 'Admins',
    path: '/admins',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  billing: {
    name: 'Billing',
    path: '/billing',
    roles: [Roles.TEAM_OWNER],
  },
  utilities: {
    name: 'Utilities',
    icon: 'icon-wrench',
    roles: [Roles.INTERNAL_ADMIN],
  },
  alcoholCalculator: {
    name: 'Alcohol Calculator',
    path: '/alcohol',
    roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  colors: {
    name: 'Design System',
    path: '/colors',
    roles: [Roles.INTERNAL_ADMIN],
  },
  fontIcons: {
    name: 'Font Icons',
    path: '/fontIcons',
    roles: [Roles.INTERNAL_ADMIN],
  },
  food: {
    name: 'Food',
    icon: 'ms-icon- icon-Knife_-Fork_-Restaurant_-Food',
    // roles: [Roles.INTERNAL_ADMIN, Roles.INTERNAL_RECIPE_ADMIN],
  },
  recipes: {
    name: 'Recipes',
    path: '/recipes',
    // roles: [Roles.INTERNAL_ADMIN, Roles.INTERNAL_RECIPE_ADMIN, Roles.TEAM_ADMIN, Roles.TEAM_OWNER],
  },
  foods: {
    name: 'Foods',
    path: '/foods',
    roles: [Roles.INTERNAL_ADMIN, Roles.INTERNAL_RECIPE_ADMIN],
  },
  team: {
    name: 'Macrostax Team',
    icon: 'ms-icon- icon-dumbbell',
    roles: [Roles.INTERNAL_ADMIN],
  },
  teamSearch: {
    name: 'Team Search',
    path: '/teams',
    roles: [Roles.INTERNAL_ADMIN],
  },
  teamBranding: {
    name: 'Team Branding',
    path: '/teambranding',
    roles: [Roles.INTERNAL_ADMIN],
  },
  users: {
    name: 'Users',
    icon: 'icon-user',
    roles: [Roles.INTERNAL_ADMIN],
  },
  userSearch: {
    name: 'User Search',
    path: '/users',
    roles: [Roles.INTERNAL_ADMIN],
  },
  resources: {
    name: 'Resources',
    icon: 'icon-question',
    roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
  },
  helpCenter: {
    name: 'Help Center',
    path: 'https://help.macrostax.com',
    external: true,
  },
  toolkit: {
    name: 'Launch Guide',
    path: '/launchguide',
  },
};

// this is what populates the sidebar menu
const Menu = [
  {
    ...AuthMenu.dashboard,
  },
  {
    ...AuthMenu.clients,
    submenu: [AuthMenu.clientList, AuthMenu.addClients],
  },
  {
    ...AuthMenu.messaging,
    submenu: [AuthMenu.messages],
    isChat: true,
  },
  {
    ...AuthMenu.challenges,
    submenu: [AuthMenu.challengesList, AuthMenu.addChallenge, AuthMenu.challengeGuide],
  },
  {
    ...AuthMenu.users,
    submenu: [AuthMenu.userSearch],
  },
  {
    ...AuthMenu.team,
    submenu: [AuthMenu.teamSearch, AuthMenu.teamBranding],
  },
  {
    ...AuthMenu.content,
    submenu: [AuthMenu.menuLinks, AuthMenu.homeContent],
    // submenu: [AuthMenu.recipes, AuthMenu.foods],
  },
  {
    ...AuthMenu.food,
    submenu: [AuthMenu.recipes],
    // submenu: [AuthMenu.recipes, AuthMenu.foods],
  },
  {
    ...AuthMenu.settings,
    submenu: [
      AuthMenu.accountSettings,
      AuthMenu.businessSettings,
      AuthMenu.adminUsers,
      AuthMenu.billing,
      AuthMenu.integrations,
    ],
  },
  // {
  //   ...AuthMenu.referralprogram,
  //   submenu: [AuthMenu.referrals],
  // },
  {
    ...AuthMenu.utilities,
    submenu: [AuthMenu.alcoholCalculator, AuthMenu.colors],
  },
  // {
  //   ...AuthMenu.resources,
  //   submenu: [AuthMenu.helpCenter, AuthMenu.toolkit]
  // }
];

export default Menu;
