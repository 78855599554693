import React, { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import renderSelectForField from './helpers/renderSelectForField';

const validate = () => {
  const errors = {};
  return errors;
};

const ImportUsersByLocationForm = ({
  onSubmit,
  showSubmissionError,
  initialValues,
  data,
  isLoading,
  }) => {
    const locationOptions = useMemo(() =>
      data.map((location) =>
        ({
          label: location.name,
          value: `${location.id}`
        })
      ), [JSON.stringify(data)]);

    const onSubmitClick = (values) => {
      let vals = false;
      for (let i = 0; i < Object.keys(values).length; i += 1) {
        if (values[Object.keys(values)[i]]) {
          vals = true;
        }
      }
      if (!vals) {
        showSubmissionError({ error: 'Please enter some search criteria' });
      } else {
        const errors = validate(values);
        if (Object.keys(errors).length === 0) {
          const location_ids = values.locations.map((locationId) => parseInt(locationId));
          onSubmit({ location_ids });
        } else {
          showSubmissionError(errors);
        }
      }
    };

    return (
      <div>
        <Form
          onSubmit={(values) => onSubmitClick(values)}
          initialValues={initialValues}
          decorators={[]}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit} className="inline-form">
                <Row>
                  <Col md={12}>
                    <Field
                      name="locations"
                      label="Locations"
                      component={renderSelectForField}
                      {...{
                          itemProps: {},
                          inputProps: {
                            options: locationOptions,
                            isMulti: true,
                          },
                        }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={6}>
                    <button
                      className="btn btn-lg btn-primary fixed-width-lg btn-search-with-reset"
                      type="submit"
                      disabled={submitting || isLoading}
                    >
                      Import Members
                    </button>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      </div>
    );
};

ImportUsersByLocationForm.propTypes = {
  data: PropTypes.instanceOf(Array),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default ImportUsersByLocationForm;
