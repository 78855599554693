export const Roles = {
  TEAM_OWNER: 'team_owner',
  TEAM_ADMIN: 'team_admin',
  INTERNAL_ADMIN: 'internal_admin',
  INTERNAL_RECIPE_ADMIN: 'internal_recipe_admin',
};

export const adminRoleOptions = [
  {
    label: 'Admin',
    value: 'team_admin',
  },
  {
    label: 'Owner',
    value: 'team_owner',
  },
];

export const internalAdminRoleOptions = [
  {
    label: 'Admin',
    value: 'internal_admin',
  },
  {
    label: 'Recipe Admin',
    value: 'internal_recipe_admin',
  },
];

export const rolesById = {
  '429f0258-f50d-424e-a017-afa0fe69efa7': 'Team Owner',
  'dfa90da8-bc36-4d62-9482-b51c9fd0f047': 'Team Admin',
  '9b23e53c-625d-4f45-8b4f-2387f456e80f': 'Team Consumer',
};
