import React, { useEffect, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import ImportUsersByLocationForm from '../../forms/ImportUsersByLocationForm';
import { submissionError } from '../../actions/formActions';
import { cancelImportUsersRequest, getAllLocationsFromIntegration, getImportStatus, importUsersRequest, resetIntegrationStatus } from '../../actions/integrationActions';
import { IN_PROGRESS_STATUS } from '../../enums/importStatus';
import StatusInformationCard from './StatusInformationCard';

const IntegrationImportUsers = ({
  history,
  match,
  doGetLocations,
  doGetImportStatus,
  doResetImportStatus,
  locations,
  integrationStatus,
  onSubmit,
  onCancel,
  initialValues,
  showSubmissionError,
  }) => {
  const { status, error, started } = integrationStatus;
  const integrationId = match?.params?.integration_id;
  const isInProgress = useMemo(() => status === IN_PROGRESS_STATUS && started, [status]);

  const handleBackClick = () => {
    history.push('/integrations');
  };

  const handleImportUsers = async (values) => {
    await onSubmit(values, integrationId);
    await doGetImportStatus(integrationId);
  };

  const handleCancelImport = () => {
    onCancel(integrationId);
    doResetImportStatus();
  };

  useEffect(() => {
    doResetImportStatus();
    doGetLocations(integrationId);
  }, []);

  useEffect(() => {
    let interval;
    if (started) {
      interval = setInterval(async () => {
        await doGetImportStatus(integrationId);
      }, 500);
    }

    if (error) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [status, started]);

  return (
    <ContentWrapper className="user-detail-container">
      <Row>
        <Col xs={12}>
          <PageTitle title="Import Members" />
          <div className="mb-4 mt-n2">
            <button onClick={handleBackClick} className="btn btn-link">
              <em className="fa-lg mr-2 fas fa-arrow-left" />
              Back to Integrations
            </button>
          </div>
        </Col>
      </Row>

      <div className="main-content-container">
        <Row className="mt-12">
          <Col xs={12}>
            <p>
              Please, select the locations you want to import members from.
            </p>
          </Col>
        </Row>

        <Row className="mt-12">
          <Col xs={6}>
            {locations && (
              <ImportUsersByLocationForm
                initialValues={initialValues}
                data={locations}
                onSubmit={handleImportUsers}
                isLoading={isInProgress}
                showSubmissionError={showSubmissionError}
              />
            )}
          </Col>
        </Row>
      </div>

      {started && (
        <StatusInformationCard
          status={status}
          onCancel={handleCancelImport}
        />
      )}
    </ContentWrapper>
  );
};

IntegrationImportUsers.propTypes = {
  match: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  doGetLocations: PropTypes.func,
  doGetImportStatus: PropTypes.func,
  doResetImportStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  locations: state.integrations.locations,
  integrationStatus: state.integrations.integrationStatus,
  initialValues: {
    locations: [],
    ...state.context.setupIntegrationForm,
  },
});

const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  onSubmit: (values, id) => dispatch(importUsersRequest(values, id)),
  onCancel: (id) => dispatch(cancelImportUsersRequest(id)),
  doGetLocations: (integrationId) => dispatch(getAllLocationsFromIntegration(integrationId)),
  doGetImportStatus: (integrationId) => dispatch(getImportStatus(integrationId)),
  doResetImportStatus: () => dispatch(resetIntegrationStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationImportUsers);
