import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import CardIcon from './CardIcon';

class GreenThumbsCard extends Component {
  render() {
    const { allTimeGreenThumbs, allTimeGreenThumbsBy } = this.props;
    return (
      <Card className="card-default card-container mb-3 green-thumbs-card flex-grow-1">
        <CardHeader>
          All-Time Green Thumbs
          <CardIcon helpText="ALL_TIME_GREEN_THUMBS" />
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="green-thumb-column d-flex flex-column">
              <div className="flex flex-row justify-content-center">
                <div className="green-thumb-container align-self-center mb-2 mr-2">
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" />
                </div>
                <div className="green-thumb-container align-self-center mb-2 mr-2">
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" />
                </div>
                <div className="green-thumb-container align-self-center mb-2">
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" />
                </div>
              </div>
              <div className="statistic mb-1">{allTimeGreenThumbs || 0}</div>
              <div className="card-caption">
                {`From ${allTimeGreenThumbsBy} client${allTimeGreenThumbsBy !== 1 ? 's' : ''}`}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

GreenThumbsCard.propTypes = {
  allTimeGreenThumbs: PropTypes.number,
  allTimeGreenThumbsBy: PropTypes.number,
};

export default GreenThumbsCard;
