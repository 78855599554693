import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import ShareRecipeForm from '../forms/ShareRecipeForm';
import Loading from '../components/Common/Loading';
import { submissionError } from '../actions/formActions';
import { shareRecipe } from '../actions/foodActions';
import { isTeamUser } from '../utils/authUtils';
import browserHistory from '../history';

class ShareRecipeModal extends Component {
  render() {
    const { isOpen, toggle, type, clients = [], showSubmissionError, doShareRecipe, recipeId } = this.props;
    const initialValues = {
      recipeId,
    };
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/clientList', title: 'Client List' });
    }
    let filteredClients;
    if (clients !== undefined) {
      filteredClients = clients.filter((c) => (c.user_role_status === 'active' || c.user_role_status === 'invited'));
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} scrollable={true}>
        <ModalHeader toggle={toggle}>
          <PageTitle title="Share Recipe" showTeam={false} icon="fa-1x fas fa-share-square" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className="animated">
              {filteredClients === undefined || filteredClients.length === 0 ? (
                <Loading />
              ) : (
                <ShareRecipeForm
                  initialValues={initialValues}
                  clients={filteredClients}
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => {
                    // doUpsertChallengeClients(id, values);
                    // console.log(values);
                    doShareRecipe(values, toggle);
                  }}
                />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

ShareRecipeModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  type: PropTypes.string,
  clients: PropTypes.instanceOf(Array),
  showSubmissionError: PropTypes.func,
  doShareRecipe: PropTypes.func,
  recipeId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doShareRecipe: (values, toggle) => dispatch(shareRecipe(values, toggle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareRecipeModal);
