import React, { Component, useState, CSSProperties } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import tableIcons from '../../utils/muiIcons';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import UserAvatar from '../../components/Common/UserAvatar';
import CSVUploader from '../../components/Common/CSVUploader.tsx';

/* TODO: delete /public/users.csv */

class CSVUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
    };
  }

  mapResults = (csv) => {
    const results = csv.csv;
    if (results.errors && results.errors.length) {
      alert('Error');
      console.log(results.errors);
    } else {
      if (results.data[0].length !== 3) {
        alert('Incorrect CSV format - should be "first_name","last_name","email"');
      } else {
        const usersToUpload = [];
        for (let i = 0; i < results.data.length; i += 1) {
          const user = results.data[i];
          usersToUpload.push({
            first_name: user[0],
            last_name: user[1],
            email: user[2],
          });
        }
        this.setState({ results: usersToUpload });
      }
    }
  };
  render() {
    const columns = [
      {
        title: '',
        field: 'profile_photo',
        render: (rowData) => <UserAvatar user={rowData} />,
        sorting: false,
        width: null,
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        headerStyle: {
          color: 'red',
        },
      },
      { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
      { title: 'First Name', field: 'first_name' },
      {
        title: 'Email',
        field: 'email',
      },
    ];
    const { results } = this.state;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="CSV Upload Test" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row>
            <Col xs={12}>
              This proof of concept will accept a CSV file to parse and output the results of the
              file. In the future, we could iterate over each row and invite each user to the team,
              or send them all as an array to a new endpoint. But this way we don't have to worry
              about uploading files to our servers and doing validation there, etc.{' '}
              <a href="/users.csv">Sample CSV file</a>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={4}>
              <CSVUploader onLoaded={(csv) => this.mapResults({ csv })} />
            </Col>
          </Row>
          {results && results.length ? (
            <Row className="mt-5">
              <Col xs={12}>
                <MaterialTable
                  columns={columns}
                  data={results}
                  title="CSV Upload Results"
                  key={results.length} // https://github.com/mbrn/material-table/issues/1480
                  options={{
                    padding: 'dense',
                    pageSize: results.length < 10 ? results.length : 10,
                    search: true,
                    paging: results.length > 10,
                    sorting: true,
                    thirdSortClick: false,
                    showTitle: true,
                    toolbar: true,
                  }}
                  style={{
                    backgroundColor: '#f7f7f7',
                  }}
                  icons={tableIcons}
                />
              </Col>
            </Row>
          ) : null}
        </div>
      </ContentWrapper>
    );
  }
}

CSVUpload.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CSVUpload);
