import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addClients,
  sendInvitations,
} from '../../actions/teamActions';
import AddClientForm from '../../forms/AddClientForm';
import AddClientsFromCsvForm from '../../forms/AddClientsFromCsvForm';
import CSVUploader from '../Common/CSVUploader.tsx';

class AddClientsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSub: 'individually',
      results: [],
      csvInitialValues: {},
    }
  }

  setActiveSub = (activeSub) => {
    this.setState({
      activeSub,
    });
  };

  mapResults = (csv) => {
    const results = csv.csv;
    if (results.errors && results.errors.length) {
      alert('Error');
      console.log(results.errors);
    } else {
      if (results.data[0].length !== 3) {
        alert('Incorrect CSV format - should be "first_name","last_name","email"');
      } else {
        const usersToUpload = [];
        const csvInitialValues = {
          send_invitations: true,
        };
        for (let i = 0; i < results.data.length; i += 1) {
          const user = results.data[i];
          usersToUpload.push({
            first_name: user[0],
            last_name: user[1],
            email: user[2],
          });
          csvInitialValues[`user_${i}`] = true;
        }
        this.setState({ results: usersToUpload, csvInitialValues });
      }
    }
  };

  render() {
    const { team, doAddClients, showSubmissionError, toggleTab } = this.props;
    const { activeSub, results, csvInitialValues } = this.state;
    const buttons = [
      {
        title: 'Upload Individually',
        id: 'individually',
      },
      {
        title: 'Upload CSV File',
        id: 'csv',
      },
    ];
    return (
      <>
        <Row className="add-clients">
          <Col>
            <div className="btn-group btn-group-toggle mt-4">
              {buttons.map((b) => (
                <label key={b.id} className={`btn btn-secondary ${activeSub === b.id && 'active'}`}>
                  <input
                    type="radio"
                    name="options"
                    id={b.id}
                    autoComplete="off"
                    defaultChecked={activeSub === b.id}
                    onClick={() => this.setState({ activeSub: b.id })}
                  />{' '}
                  {b.title}
                </label>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {activeSub === 'individually' ? (
              <>
                <Row className="mt-3">
                  <Col xs={4}>First Name</Col>
                  <Col xs={4}>Last Name</Col>
                  <Col xs={4}>Email</Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <AddClientForm
                      showSubmissionError={showSubmissionError}
                      onSubmit={(values, form, rows) => {
                        const newInitialValues = {
                          send_invitations: true,
                        };
                        for (let i = 0; i < rows; i += 1) {
                          newInitialValues[`first_name_${i}`] = '';
                          newInitialValues[`last_name_${i}`] = '';
                          newInitialValues[`email_${i}`] = '';
                        }
                        values.impersonate_team_owner = true;
                        values.team_id = team.team_id;
                        doAddClients(values, () => {
                          setTimeout(() => {
                            form.reset(newInitialValues);
                          });
                          toggleTab('manageinvites');
                        });
                      }}
                      rows={5}
                      initialValues={{
                        send_invitations: true,
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="mt-4">
                  <Col sm={4} xs={8} className="cursor-pointer">
                    <CSVUploader onLoaded={(csv) => this.mapResults({ csv })} />
                  </Col>
                </Row>
                {results && results.length ? (
                  <AddClientsFromCsvForm
                    showSubmissionError={showSubmissionError}
                    onSubmit={(values) => {
                      values.impersonate_team_owner = true;
                      values.team_id = team.team_id;
                      doAddClients(values, () => toggleTab('manageinvites'));
                    }}
                    users={results}
                    initialValues={csvInitialValues}
                  />
                ) : null}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

AddClientsControl.propTypes = {
  team: PropTypes.instanceOf(Object),
  toggleTab: PropTypes.func,
  doAddClients: PropTypes.func,
  showSubmissionError: PropTypes.func,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doAddClients: (values, func) => dispatch(addClients(values, func)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientsControl);

