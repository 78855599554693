import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CardIcon from './CardIcon';
import GreenThumbsLeaderboard from '../Leaderboards/GreenThumbsLeaderboard';

class GreenThumbsLeaderboardCard extends Component {
  render() {
    const { greenThumbsLeaderboard = [] } = this.props;
    return (
      <Card className="card-default card-container mb-3 green-thumbs-card flex-grow-1">
        <CardHeader>
          Green Thumbs Leaderboard
          <CardIcon helpText="GREEN_THUMBS_LEADERBOARD" />
        </CardHeader>
        <CardBody>
          {greenThumbsLeaderboard.length > 0 && (
            <div className="mb-3 mt-n1">
              <a href="">Send a note to your leaderboard</a>
            </div>
          )}
          <GreenThumbsLeaderboard leaderboard={greenThumbsLeaderboard} />
        </CardBody>
      </Card>
    );
  }
}

GreenThumbsLeaderboardCard.propTypes = {
  greenThumbsLeaderboard: PropTypes.instanceOf(Array),
};

export default GreenThumbsLeaderboardCard;
