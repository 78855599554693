import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PieChart from './PieChart';
import { buildSingleStatisticPieChartData } from '../../utils/chartUtils';

class SingleStatisticPieChart extends Component {
  render() {
    const { percent, label } = this.props;
    const pie = buildSingleStatisticPieChartData(percent, label);
    return <PieChart pie={pie} />;
  }
}

SingleStatisticPieChart.propTypes = {
  percent: PropTypes.number,
  label: PropTypes.string,
};

export default SingleStatisticPieChart;
