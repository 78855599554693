import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import RecipeResult from '../RecipeResult';

class RecipeList extends Component {
  render() {
    const { recipeList = [] } = this.props;
    return (
      <Row>
        <Col>
          {recipeList.map((recipe) => (
            <RecipeResult key={recipe.id} recipe={recipe} />
          ))}
        </Col>
      </Row>
    );
  }
}

RecipeList.propTypes = {
  recipeList: PropTypes.instanceOf(Array),
};

export default RecipeList;
