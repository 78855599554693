import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import Loading from '../components/Common/Loading';
import PendingClientsForm from '../forms/PendingClientsForm';
import Callout from '../components/Common/Callout';
import AddClientForm from '../forms/AddClientForm';
import { makeSimpleValue } from '../utils/formUtils';
import { submissionError } from '../actions/formActions';
import { addUser } from '../actions/userActions';
import { submitUserSearchRequest, clearUserSearchForm } from '../actions/userActions';
import { addAdmins } from '../actions/teamActions';

class InviteClientModal extends Component {
  componentDidMount() {
    const { getClients } = this.props;
    getClients();
  }
  render() {
    const {
      isOpen,
      toggle,
      doAddUser,
      showSubmissionError,
      type = 'client',
      preview = false,
      team,
      clients,
      doAddAdmins,
    } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/inviteAdmin', title: 'Add Admins' });
    }
    let admins;
    let clientsForGrid;
    if (team) {
      ({ admins } = team);
    }
    const title = type === 'admin' ? 'Add Admins' : preview ? 'Send an Invite' : 'Invite Client';
    const label = type === 'admin' ? 'admin' : 'client';

    if (clients && admins) {
      // exclude all clients that are already admins and/or are revoked
      clientsForGrid = clients.filter(
        (c) =>
          !admins.find((a) => a.id === c.id && a.user_role_status !== 'revoked') &&
          c.user_role_status !== 'revoked'
      );
    }
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetState}
        className={!preview ? 'modal-large' : ''}
        scrollable={true}
      >
        <ModalHeader toggle={toggle}>
          <PageTitle title={title} showTeam={false} icon="fa-1x fa fa-user-plus" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {!clients ? (
                <Loading />
              ) : (
                <>
                  <div>Please select which client(s) you would like to add as a Team Admin:</div>

                  <PendingClientsForm
                    showSubmissionError={showSubmissionError}
                    onSubmit={(values) => {
                      doAddAdmins(values, toggle);
                    }}
                    users={clientsForGrid}
                    initialValues={{}}
                    team={team}
                    showStatus={false}
                    pageSize={10}
                    showRemove={false}
                    onRowClick={() => {}}
                    buttonText="Add Admins"
                    title="Add Clients as Admins"
                    showSearch={false}
                    isAdminForm={true}
                  />
                </>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

InviteClientModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  doAddUser: PropTypes.func,
  showSubmissionError: PropTypes.func,
  type: PropTypes.string,
  preview: PropTypes.bool,
  team: PropTypes.instanceOf(Object),
  getClients: PropTypes.func,
  clients: PropTypes.instanceOf(Array),
  doAddAdmins: PropTypes.func,
};

const mapStateToProps = (state) => ({
  clients: state.clients.userSearchResults,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  doAddAdmins: (values, toggle) => dispatch(addAdmins(values, toggle)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  getClients: () => {
    dispatch(clearUserSearchForm());
    dispatch(submitUserSearchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteClientModal);
