import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Row, Col } from 'reactstrap';
import SampleDashboardModal from '../../modals/SampleDashboardModal';
import OnboardingModal from '../../modals/OnboardingModal';
import InviteClientModal from '../../modals/InviteClientModal';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import TeamDashboardContainer from '../../containers/Teams/TeamDashboardContainer';
import { userHasRole, isTeamUser } from '../../utils/authUtils';
import { getDashboard, getEngagementSummary, getClientStatusAndGoalDistribution, getEngagementSummaryCsv, clearEngagementSummaryCsv } from '../../actions/teamActions';
import browserHistory from '../../history';

import { Roles } from '../../enums/roles';

const now = moment();
let dashboardStartDate = now.clone().add(-30, 'days');
let dashboardEndDate = now;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleModalOpen: false,
      onboardingModalOpen: false,
    };
  }

  componentDidMount() {
    const { doGetEngagementSummary, doGetClientStatusAndGoalDistribution } = this.props;
    const {
      location: { search },
    } = browserHistory;

    const searchParams = queryString.parse(search);
    if (searchParams && searchParams.onboarding) {
      this.toggleOnboardingModal();
    }
    doGetEngagementSummary();
    doGetClientStatusAndGoalDistribution();
  }

  toggleSampleModal = () => {
    const { sampleModalOpen } = this.state;
    this.setState({
      sampleModalOpen: !sampleModalOpen,
    });
  };

  toggleOnboardingModal = () => {
    const { onboardingModalOpen } = this.state;
    this.setState({
      onboardingModalOpen: !onboardingModalOpen,
    });
  };

  toggleInviteClientModal = () => {
    const { inviteClientModalOpen } = this.state;
    this.setState({
      inviteClientModalOpen: !inviteClientModalOpen,
    });
  };

  updateEngagementSummary = (team_id, startDate, endDate, sortBy, order, page, showCount, clearData = false) => {
    const { doGetEngagementSummary } = this.props;
    dashboardStartDate = startDate;
    dashboardEndDate = endDate;
    doGetEngagementSummary(
      team_id,
      dashboardStartDate,
      dashboardEndDate,
      sortBy,
      order,
      page,
      showCount,
      clearData,
    );
  };

  render() {
    const { team, user, doGetEngagementSummaryCsv, doClearEngagementSummaryCsv } = this.props;
    const { roles } = user;
    const { sampleModalOpen, onboardingModalOpen, inviteClientModalOpen } = this.state;

    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Dashboard" />
            {!isTeamUser(roles) && (
              <div className="main-content-container">
                <div className="mb-3">{roles}-specific dashboard will go here...</div>
              </div>
            )}
            {/* {isTeamUser(roles) && (
              <div className="mb-4 mt-n2">
                <button className="btn-text" onClick={this.toggleSampleModal}>
                  View Sample
                </button>
              </div>
            )} */}
          </Col>
        </Row>
        {isTeamUser(roles) && team && team.engagementSummary && team.membership && team.counts && team.goal_distribution ? (
          <div className="main-content-container">
            <TeamDashboardContainer
              team={team}
              isTeamOwner={true}
              doGetEngagementSummary={this.updateEngagementSummary}
              startDate={dashboardStartDate}
              endDate={dashboardEndDate}
              doGetEngagementSummaryCsv={doGetEngagementSummaryCsv}
              doClearEngagementSummaryCsv={doClearEngagementSummaryCsv}
            />
            {/* <SampleDashboardModal isOpen={sampleModalOpen} toggle={this.toggleSampleModal} />
            <OnboardingModal
              isOpen={onboardingModalOpen}
              toggle={this.toggleOnboardingModal}
              postAction={this.toggleInviteClientModal}
            /> */}
          </div>
        ) : (
          <div className="mt-5">
            <Loading />
          </div>
        )}

        {/* <InviteClientModal
          isOpen={inviteClientModalOpen}
          toggle={this.toggleInviteClientModal}
          preview
        /> */}
      </ContentWrapper>
    );
  }
}

Dashboard.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  doGetEngagementSummary: PropTypes.func,
  doGetClientStatusAndGoalDistribution: PropTypes.func,
  doGetEngagementSummaryCsv: PropTypes.func,
  doClearEngagementSummaryCsv: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.team,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doGetClientStatusAndGoalDistribution: () => dispatch(getClientStatusAndGoalDistribution()),
  doGetEngagementSummaryCsv: (team_id, start_date, end_date) => dispatch(getEngagementSummaryCsv(team_id, start_date, end_date)),
  doClearEngagementSummaryCsv: () => dispatch(clearEngagementSummaryCsv()),
  doGetEngagementSummary: (
    team_id,
    dashboardStartDate,
    dashboardEndDate,
    sortBy,
    order,
    page,
    showCount,
    clearData
  ) =>
    dispatch(
      getEngagementSummary(
        team_id,
        dashboardStartDate,
        dashboardEndDate,
        sortBy,
        order,
        page,
        showCount,
        clearData
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
