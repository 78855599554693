import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import UserTable from '../components/Tables/UserTable';
import { isTeamUser } from '../utils/authUtils';
import browserHistory from '../history';

class ClientListModal extends Component {
  goToUserDetail = (user) => {
    const { currentUser } = this.props;
    const { roles } = currentUser;
    const teamAdmin = isTeamUser(roles);
    browserHistory.push(`/${teamAdmin ? 'clients' : 'users'}/${user.id}`);
  };

  render() {
    const { isOpen, toggle, type, users = [] } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/clientList', title: 'Client List' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <PageTitle title={`${type} Clients`} showTeam={false} icon="fa-1x icon-user" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className="animated">
              <UserTable
                users={users}
                onRowClick={(e, rowData) => this.goToUserDetail(rowData)}
                statusColumn="status"
                showTitle={false}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

ClientListModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  type: PropTypes.string,
  users: PropTypes.instanceOf(Array),
  currentUser: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientListModal);
