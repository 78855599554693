import React, { useEffect, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import SetupIntegrationForm from '../../forms/SetupIntegrationForm';
import { submissionError } from '../../actions/formActions';
import { submitIntegrationSetupRequest } from '../../actions/integrationActions';
import { getIntegrations } from '../../apiActions/integrationApiActions';

const ContactIntegrationProvider = ({
    history,
    match,
    initialValues,
    showSubmissionError,
    onSubmit,
    integrations,
    doGetIntegrations,
    }) => {
    const integrationId = match?.params?.integration_id;
    const integrationName = useMemo(
        () => integrations.find(
          (integration) => integration.id === integrationId
        )?.title || '...',
        [integrations, integrationId]
    );

    const handleBackClick = () => {
      history.push('/integrations');
    };

    useEffect(() => {
      doGetIntegrations();
    }, []);

    return (
      <ContentWrapper className="user-detail-container">
        <Row>
          <Col xs={12}>
            <PageTitle title="Integration Provider" />
            <div className="mb-4 mt-n2">
              <button onClick={handleBackClick} className="btn btn-link">
                <em className="fa-lg mr-2 fas fa-arrow-left" />
                Back to Integrations
              </button>
            </div>
          </Col>
        </Row>

        <div className="main-content-container">
          <Row className="mt-12">
            <Col xs={12}>
              <p>
                To import the users, you first need to contact the <strong>{integrationName} team</strong>.
              </p>

              <p>
                Once access is requested, we will send you an email when you can access the contacts.
              </p>
            </Col>
          </Row>

          <Row className="mt-12">
            <Col xs={12}>
              <div className="mt-4">
                <p>
                  Or you can setup the integration by providing the following information.:
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-12">
            <Col xs={6}>
              <SetupIntegrationForm
                onSubmit={(values) => onSubmit(values, integrationId)}
                initialValues={initialValues}
                showSubmissionError={showSubmissionError}
              />
            </Col>
          </Row>
        </div>
      </ContentWrapper>
    );
};

ContactIntegrationProvider.propTypes = {
  match: PropTypes.instanceOf(Object),
  integrations: PropTypes.instanceOf(Array),
  showSubmissionError: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.instanceOf(Object),
  doGetIntegrations: PropTypes.func,
};
const mapStateToProps = (state) => ({
  integrations: state.integrations.integrationResults,
  initialValues: {
    domain: '',
    api_key: '',
    ...state.context.setupIntegrationForm,
  },
});

const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  onSubmit: (values, id) => {
    dispatch(submitIntegrationSetupRequest(values, id));
  },
  doGetIntegrations: () => dispatch(getIntegrations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactIntegrationProvider);
