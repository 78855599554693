import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  ChannelList,
  InfiniteScroll,
  Avatar,
  useChannelStateContext,
  useUserPresenceChangedListener,
} from 'stream-chat-react';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import { upsertStreamUsers, clearStreamTokens } from '../../actions/authActions';
import browserHistory from '../../history';
import ENV_VARS from '../../utils/envVars';
import { formatFromNowDate } from '../../utils/dateUtils';

import 'stream-chat-react/dist/css/v2/index.css';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);
let connected = false;
chatClient.on('connection.changed', (e) => {
  if (e.online) {
    connected = true;
  }
});

export function Chats({ currentUser, doClearStreamTokens, doUpsertStreamUsers, team }) {
  useEffect(() => {
    doClearStreamTokens();
    const users = {};
    users[currentUser.user_id] = {
      id: currentUser.user_id,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
    };
    doUpsertStreamUsers(users);
  }, []);

  let chatTokens;
  let filters;
  const sort = { last_message_at: -1 };
  if (
    team !== undefined &&
    team.chatTokens !== undefined &&
    currentUser !== undefined &&
    currentUser.user_id !== undefined
  ) {
    ({ chatTokens } = team);
    filters = { members: { $in: [currentUser.user_id] } };
    // TODO: the user should be connected in initSaga, but there could be a race condition here.
    // look into hooking into connection status events to set a local property that we can reference
    // below in the render functionality
    //
    // chatClient.connectUser(
    //   {
    //     id: currentUser.user_id,
    //     name: `${currentUser.first_name} ${currentUser.last_name}`,
    //   },
    //   chatTokens[currentUser.user_id]
    // );
  }

  return (
    <ContentWrapper>
      <Row>
        <Col xs={12}>
          <PageTitle title="Messaging" />
        </Col>
      </Row>
      {currentUser && team && chatTokens && connected ? (
        <div className="all-chats-container">
          <Row>
            <Col xs={12} className="d-flex width-100-percent">
              <Chat client={chatClient}>
                <ChannelList filters={filters} sort={sort} Paginator={InfiniteScroll} />
                <Channel>
                  <Window>
                    <ChannelInfo currentUser={currentUser} />
                    <MessageList disableDateSeparator={false} formatDate={formatFromNowDate} />
                    <MessageInput grow={true} />
                  </Window>
                  <Thread />
                </Channel>
              </Chat>
            </Col>
            {/* <Col xs={12} className="d-flex width-100-percent">
              <Chat client={chatClient}>
                <ChannelList filters={filters} sort={sort} Paginator={InfiniteScroll} />
                <Channel>
                  <Window>
                    <ChannelInfo currentUser={currentUser} />
                    <MessageList disableDateSeparator={false} formatDate={formatFromNowDate} />
                    <MessageInput grow={true} />
                  </Window>
                  <Thread />
                </Channel>
              </Chat>
            </Col> */}
          </Row>
        </div>
      ) : (
        <Loading />
      )}
    </ContentWrapper>
  );
}

Chats.propTypes = {
  currentUser: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doClearStreamTokens: PropTypes.func,
  doGetStreamToken: PropTypes.func,
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  doClearStreamTokens: () => dispatch(clearStreamTokens()),
  doUpsertStreamUsers: (users) => dispatch(upsertStreamUsers(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chats);

const ChannelInfo = ({ currentUser }) => {
  const { channel } = useChannelStateContext();

  const goToClient = (user_id) => {
    browserHistory.push({
      pathname: `/clients/${user_id}?chat=true`,
      state: { from: 'Messages' }
    });
  };

  const renderMembers = () => {
    const members = Object.values(channel.state.members).filter(
      (user) => user.user_id !== currentUser.user_id
    );

    return members.map((member) => {
      return (
        <div
          key={`member_header_${member.user_id}`}
          className="str-chat__header-livestream str-chat__channel-header"
          onClick={() => goToClient(member.user_id)}
          style={{ cursor: 'pointer' }}
        >
          <Avatar name={member.user?.name} size={40} />
          <div className="str-chat__header-livestream-left str-chat__channel-header-end">
            <p className="str-chat__header-livestream-left--title str-chat__channel-header-title">
              {member.user?.name}
            </p>
          </div>
        </div>
      );
    });
  };

  return renderMembers();
};

const CustomListItem = (props) => {
  // render custom list item here
  console.log(props);
  // channel.state.members
  return <div>yo</div>;
};
