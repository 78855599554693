import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { firstBy, thenBy } from 'thenby';
import numeral from 'numeral';
import PillHeader from '../Common/PillHeader';

class DashboardLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientSort: 'asc',
      activeSort: 'desc',
      loggingSort: 'desc',
      weightSort: 'asc',
      bodyFatSort: 'asc',
      greenThumbsSort: 'desc',
      carbsSort: 'desc',
      proteinSort: 'desc',
      fatSort: 'desc',
      caloriesSort: 'desc',
    };
  }

  render() {
    const { goal, lbsVerb, isTeamOwner = true, team_id } = this.props;
    const { leaderboard = [] } = this.props;

    const sortLeaderboard = (column) => {
      const { clientSort, activeSort, loggingSort, weightSort, bodyFatSort, greenThumbsSort, carbsSort, proteinSort, fatSort, caloriesSort } = this.state;
      let { leaderboard } = this.props;
      // TODO: a bit of a hack, but to get out the door quickly
      switch (column) {
        case 'client':
          leaderboard = leaderboard.sort(
            firstBy('name', clientSort)
            .thenBy('daysActive', 'desc')
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('lbs')
            .thenBy('bodyFat')
            .thenBy('greenThumbsPct')
          );
          this.setState({
            clientSort: clientSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'active':
          leaderboard = leaderboard.sort(
            firstBy('daysActive', activeSort)
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
            .thenBy('greenThumbsPct')
          );
          this.setState({
            activeSort: activeSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'logging':
          leaderboard = leaderboard.sort(
            firstBy('daysLoggingFood', loggingSort)
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
            .thenBy('greenThumbsPct')
          );
          this.setState({
            loggingSort: loggingSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'weight':
          leaderboard = leaderboard.sort(
            firstBy('lbs', weightSort)
            .thenBy('bodyFat')
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('greenThumbsPct')
          );
          this.setState({
            weightSort: weightSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'bodyFat':
          leaderboard = leaderboard.sort(
            firstBy('bodyFat', bodyFatSort)
            .thenBy('lbs')
            .thenBy('bodyFat')
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('greenThumbsPct')
          );
          this.setState({
            bodyFatSort: bodyFatSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'greenThumbs':
          leaderboard = leaderboard.sort(
            firstBy('greenThumbsPct', greenThumbsSort)
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
          );
          this.setState({
            greenThumbsSort: greenThumbsSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'carbs':
          leaderboard = leaderboard.sort(
            firstBy('carbsPct', carbsSort)
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
          );
          this.setState({
            carbsSort: carbsSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'protein':
          leaderboard = leaderboard.sort(
            firstBy('proteinPct', proteinSort)
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
          );
          this.setState({
            proteinSort: proteinSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'fat':
          leaderboard = leaderboard.sort(
            firstBy('fatPct', fatSort)
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
          );
          this.setState({
            fatSort: fatSort === 'asc' ? 'desc' : 'asc',
          });
          break;
        case 'calories':
          leaderboard = leaderboard.sort(
            firstBy('caloriesPct', caloriesSort)
            .thenBy('daysLoggingFood', 'desc')
            .thenBy('daysActive', 'desc')
            .thenBy('name')
            .thenBy('lbs')
            .thenBy('bodyFat')
          );
          this.setState({
            caloriesSort: caloriesSort === 'asc' ? 'desc' : 'asc',
          });
          break;
      }
    };

    return (
      <div className="leaderboard">
        {leaderboard.length > 0 && (
          <>
            <Row className="mb-2 mt-3 leaderboard-header">
              <Col xs={3} className="cursor-pointer no-padding-right" onClick={() => sortLeaderboard('client')}>Client</Col>
              <Col xs={1} className="text-center no-wrap cursor-pointer no-padding" onClick={() => sortLeaderboard('active')}>
                Days<br />Active
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('logging')}>
                Days<br />Logging
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('greenThumbs')}>
                Green<br />Thumbs
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('carbs')}>
                Carbs<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('protein')}>
                Protein<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('fat')}>
                Fat<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('calories')}>
                Calories<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('weight')}>
                Weight<br />Change
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => sortLeaderboard('bodyFat')}>
                Body Fat<br />Change
              </Col>
            </Row>
          </>
        )}
        {leaderboard.map((user, idx) => (
          <Row key={user.id} className="mt-1">
            <Col xs={3} className=" no-padding-right">
              <Link
                to={{
                  pathname: `/${isTeamOwner ? 'clients' : 'users'}/${user.id}${isTeamOwner ? '' : `?team_id=${team_id}`}`,
                  state: { from: 'Dashboard' }
                }}
              >
                {user.name}
              </Link>
            </Col>
            <Col xs={1} className="text-center no-padding">
              {user.daysActive}
            </Col>
            <Col xs={1} className="text-center no-padding">{user.daysLoggingFood}</Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_thumbs`}>
              {isNaN(user.greenThumbsPct) || user.daysLoggingFood === 0 ? (
                <div>--</div>
                ) : (
                  <>
                    <div className="text-center">
                      {numeral(user.greenThumbsPct).format('0')}%
                    </div>
                    <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_thumbs`}>
                      <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Green Thumbs Hit: ${user.greenThumbs}/${user.possibleThumbs}</div>` }} />
                    </UncontrolledPopover>
                  </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_carbs`}>
              {isNaN(user.carbsPct) || user.daysLoggingFood === 0 ? (
                <div>--</div>
                ) : (
                  <>
                    <div className="text-center">
                      {numeral(user.carbsPct).format('0')}%
                    </div>
                    <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_carbs`}>
                      <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Carbs Green Thumb Hit: ${user.carbs}/${user.days}</div>` }} />
                    </UncontrolledPopover>
                  </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_protein`}>
              {isNaN(user.proteinPct) || user.daysLoggingFood === 0 ? (
                <div>--</div>
                ) : (
                  <>
                    <div className="text-center">
                      {numeral(user.proteinPct).format('0')}%
                    </div>
                    <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_protein`}>
                      <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Protein Green Thumb Hit: ${user.protein}/${user.days}</div>` }} />
                    </UncontrolledPopover>
                  </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_fat`}>
              {isNaN(user.fatPct) || user.daysLoggingFood === 0 ? (
                <div>--</div>
                ) : (
                  <>
                    <div className="text-center">
                      {numeral(user.fatPct).format('0')}%
                    </div>
                    <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_fat`}>
                      <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Fat Green Thumb Hit: ${user.fat}/${user.days}</div>` }} />
                    </UncontrolledPopover>
                  </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_calories`}>
              {isNaN(user.caloriesPct) || user.daysLoggingFood === 0 ? (
                <div>--</div>
                ) : (
                  <>
                    <div className="text-center">
                      {numeral(user.caloriesPct).format('0')}%
                    </div>
                    <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_calories`}>
                      <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Calories Hit +/- 100: ${user.calories}/${user.days}</div>` }} />
                    </UncontrolledPopover>
                  </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_weight`}>
              {isNaN(user.lbs) ? (
                <div>--</div>
              ) : (
                <>
                  <div className="text-right flex-grow-1 flex-basis-0 mr-1">
                    {user.lbs > 0 ? '+' : ''}
                    {numeral(user.lbs).format('0.0')}
                  </div>
                  <div className=" flex-grow-1 flex-basis-0">lb{user.lbs !== 1 ? 's' : ''}</div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_weight`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Starting weight: ${user.startingWeight} lbs<br />Ending weight: ${user.endingWeight} lbs</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.id}_bodyFat`}>
              {isNaN(user.bodyFat) ? (
                <div>--</div>
              ) : (
                <>
                  <div className="text-center">
                    {user.bodyFat > 0 ? '+' : ''}
                    {numeral(user.bodyFat).format('0.0')}%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.id}_bodyFat`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Starting body fat: ${user.startingBodyFat}%<br />Ending body fat: ${user.endingBodyFat}%</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
          </Row>
        ))}
        {leaderboard.length === 0 && (
          <Row>
            <Col className="no-data-label">
              <div>No results yet.</div>
              <p>
                Once your Team starts using the Macrostax app, you'll see a summary of their results
                here.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

DashboardLeaderboard.propTypes = {
  leaderboard: PropTypes.instanceOf(Object),
  goal: PropTypes.string,
  lbsVerb: PropTypes.string,
  isTeamOwner: PropTypes.bool,
  team_id: PropTypes.string,
};

export default DashboardLeaderboard;
