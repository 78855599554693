import React, { Component, useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { StreamChat } from 'stream-chat';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';
import Routes from './Routes';
import history from './history';
import ScrollToTop from './components/Common/ScrollToTop';
import SpinnerModal from './modals/SpinnerModal';
import ENV_VARS from './utils/envVars';
import trackers from './trackers';

import './Vendor';
import './styles/bootstrap.scss';
import './styles/app.scss';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

// const chatClient = new StreamChat(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);
export const useClient = ({ apiKey, userData, tokenOrProvider }) => {
  const [chatClient, setChatClient] = useState(null);

  useEffect(() => {
    const client = new StreamChat(apiKey);
    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    const connectionPromise = client.connectUser(userData, tokenOrProvider).then(() => {
      if (!didUserConnectInterrupt) setChatClient(client);
    });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);
      // wait for connection to finish before initiating closing sequence
      connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log('connection closed');
        });
    };
  }, [apiKey, userData.id, tokenOrProvider]);

  return chatClient;
};

class App extends Component {
  constructor(props) {
    super(props);
    let computedStates;
    let signup;
    if (store && store.liftedStore) {
      ({ computedStates } = store.liftedStore.getState());
    }
    if (computedStates && computedStates.length) {
      const lastState = computedStates[computedStates.length - 1];
      if (lastState && lastState.state && lastState.state.signup) {
        ({ signup } = lastState.state);
      }
    }
    trackers.loadTrackingScripts(signup);
  }

  componentDidMount() {
    ReactGA.initialize(ENV_VARS.GA_TRACKING_ID, { titleCase: false });
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history} basename="/">
          <ScrollToTop>
            <Routes />
            <SpinnerModal />
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
