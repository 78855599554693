import { Roles } from '../enums/roles';

export const FORM_TRANSITION_PROPS = {
  timeout: 500,
  classNames: 'fade',
  unmountOnExit: true,
};

// Stripe elements
export const STRIPE_ELEMENT_STYLE_PROPS = {
  style: {
    base: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      color: '#282625',
      borderColor: '#dcdcdc',
      borderWidth: '1px',
      fontFamily: "'Montserrat', sans-serif",
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  classes: {
    base: 'form-control',
  },
  // placeholder: '',
};

const BORDER_COLOR_FOCUSED = '#3f3f3f';
const BORDER_COLOR = '#e9e7e7';
const BASE_COLOR = '#282625';
const BACKGROUND_COLOR = '#e6e6e6';
const BLUE_COLOR = '#03b7e5';

export const customSelectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    borderBottom: `1px solid ${BORDER_COLOR}`,
    color: isFocused ? BORDER_COLOR_FOCUSED : BASE_COLOR,
    backgroundColor: isDisabled ? null : isSelected ? '#fff' : isFocused ? BACKGROUND_COLOR : null,
    ':active': {
      ...styles[':active'],
      backgroundColor: '#fff',
    },
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    borderColor: isFocused ? BORDER_COLOR_FOCUSED : BORDER_COLOR,
    // '&:hover': { borderColor: '#4a4a4a' },
    boxShadow: 'none',
    ':hover': {
      ...styles[':active'],
      backgroundColor: '#fff',
    },
    minHeight: '38px',
  }),
  container: (provided) => ({
    ...provided,
    borderColor: BORDER_COLOR,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: BASE_COLOR,
    paddingLeft: 6,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: BLUE_COLOR,
      color: BASE_COLOR,
      cursor: 'pointer',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderColor: 'red',
  }),
  valueContainer: (provided) => ({
    ...provided,
    lineHeight: '0.875rem',
    marginTop: '-2px',
  }),
};

export const allTeamRoles = [Roles.TEAM_OWNER, Roles.TEAM_ADMIN];
export const allInternalRoles = [Roles.INTERNAL_RECIPE_ADMIN, Roles.INTERNAL_ADMIN];

// List of routes that uses the signup flow/"preauth" layout
export const SIGNUP_FLOW_ROUTES = [
  '/login',
  '/signup',
  '/signup/business',
  '/signup/plans',
  '/signup/billing',
  '/signup/complete',
  '/forgotpassword',
];

// List of routes that use the "auth no nav" layout
export const AUTH_NO_NAV_ROUTES = ['/resetpassword', '/selectteam'];

export const PRINT_ROUTES = ['/printrecipe'];

export const RECIPE_PLACEHOLDER_IMAGE = 'https://macrostax.sfo2.cdn.digitaloceanspaces.com/photos/recipes/placeholder.png';

export const USER_ROLE_OPTIONS = [
  {
    value: 'pending',
    label: 'Not invited',
  },
  {
    value: 'invited',
    label: 'Pending Invite',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'revoked',
    label: 'Deactivated',
  },
];
